export const FETCH_TUNNEL_NETWORK_FEE_CURRENCY_PAIR = 'fetch:network-fee-currency-pair';
export const FETCH_TUNNEL_NETWORK_FEE = 'fetch:network-fee';
export const CREATE_WITHDRAW_CRYPTO_AUTO = 'create:withdraw-crypto-auto';
export const FETCH_DEPOSIT_AUTO_CRYPTO_ADDRESSES = 'fetch:deposit-auto-crypto-addresses';
export const FETCH_INCOMING_CURRENCY_PAIRS = 'fetch:incoming-currency-pairs';
export const FETCH_INCOMING_ADDRESSES = 'fetch:incoming-addresses';
export const CREATE_DEPOSIT_AUTO_CRYPTO_ADDRESS = 'create:deposit-auto-crypto-address';
export const CREATE_INCOMING_ADDRESS = 'create:incoming-address';
export const UPDATE_ADDRESS_ARCHIVE_STATUS = 'update:address-archive-status';
export const CREATE_OUTGOING = 'create:outgoing';
export const CALCULATE_OUTGOING = 'calculate:outgoing';
export const UPDATE_TUNNEL_API_KEY = 'update:tunnel-api-key';
export const UPDATE_TUNNEL_WEBHOOK = 'update:tunnel-webhook';
