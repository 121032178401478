'use strict';
import {CRYPTO_CURRENCIES, FIAT_CURRENCIES} from '@/constants/currencies';
import {isArray} from 'lodash';
import {getPairCurrencies} from '@/utils/converters/currency';

/**
 * @param {String} name
 * @return {Object}
 */
export function getCurrency(name) {
  const result = {
    icon: '',
    iconWithNetwork: false,
    name: 'NaN',
    label: 'NaN',
  };

  switch (name) {
    case CRYPTO_CURRENCIES.BTC:
      return {
        ...result,
        icon: '/img/icons/btc-200.png',
        name: 'BTC',
        label: 'Bitcoin',
      };

    case CRYPTO_CURRENCIES.ETH:
      return {
        ...result,
        icon: '/img/icons/eth-200.png',
        name: 'ETH',
        label: 'Ethereum',
      };

    case CRYPTO_CURRENCIES.USDT_ERC20:
      return {
        ...result,
        icon: '/img/icons/usdt-eth-260.png',
        name: 'USDT_ERC20',
        label: 'Tether ERC20',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.USDT_TRC20:
      return {
        ...result,
        icon: '/img/icons/usdt-trx-260.png',
        name: 'USDT_TRC20',
        label: 'Tether TRC20',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.USDT_Polygon:
      return {
        ...result,
        icon: '/img/icons/usdt-polygon-260.png',
        name: 'USDT_Polygon',
        label: 'USDT Polygon',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.USDT_BEP20:
      return {
        ...result,
        icon: '/img/icons/usdt-bnb-260.png',
        name: 'USDT_BEP20',
        label: 'Tether BEP20',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.USDT_SOL:
      return {
        ...result,
        icon: '/img/icons/usdt-sol-260.png',
        name: 'USDT_SOL',
        label: 'Tether SOL',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.USDC_ERC20:
    case CRYPTO_CURRENCIES.USDC:
      return {
        ...result,
        icon: '/img/icons/usdc-eth-260.png',
        name: 'USDC_ERC20',
        label: 'USDC ERC20',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.USDC_Polygon:
      return {
        ...result,
        icon: '/img/icons/usdc-polygon-260.png',
        name: 'USDC_Polygon',
        label: 'USDC Polygon',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.USDC_BEP20:
      return {
        ...result,
        icon: '/img/icons/usdc-bnb-260.png',
        name: 'USDC_BEP20',
        label: 'USDC BEP20',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.USDC_SOL:
      return {
        ...result,
        icon: '/img/icons/usdc-sol-260.png',
        name: 'USDC_SOL',
        label: 'USDC SOL',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.DAI:
      return {
        ...result,
        icon: '/img/icons/dai-200.png',
        name: 'DAI',
        label: 'Dai',
      };

    case CRYPTO_CURRENCIES.ETH_BEP20:
      return {
        ...result,
        icon: '/img/icons/eth-bep20-260.png',
        name: 'ETH_BEP20',
        label: 'ETH BEP20',
        iconWithNetwork: true,
      };

    case CRYPTO_CURRENCIES.TRX:
      return {
        ...result,
        icon: '/img/icons/trx-200.png',
        name: 'TRX',
        label: 'TRON',
      };

    case CRYPTO_CURRENCIES.MATIC_Polygon:
      return {
        ...result,
        icon: '/img/icons/polygon-200.png',
        name: 'MATIC_Polygon',
        label: 'MATIC Polygon',
      };

    case CRYPTO_CURRENCIES.BNB:
      return {
        ...result,
        icon: '/img/icons/bnb-200.png',
        name: 'BNB',
        label: 'Binance Coin',
      };

    case CRYPTO_CURRENCIES.TON:
      return {
        ...result,
        icon: '/img/icons/ton-200.png',
        name: 'TON',
        label: 'TON',
      };

    case CRYPTO_CURRENCIES.SOL:
      return {
        ...result,
        icon: '/img/icons/sol-200.png',
        name: 'SOL',
        label: 'Solana',
      };

    case FIAT_CURRENCIES.EUR:
      return {
        ...result,
        icon: '/img/icons/eur-200.png',
        name: 'EUR',
        label: 'Euro',
      };

    case FIAT_CURRENCIES.USD:
      return {
        ...result,
        icon: '/img/icons/usd-200.png',
        name: 'USD',
        label: 'Dollar',
      };

    default: return result;
  }
}

/**
 * @param {Object} currency - The currency object or value containing the data to be processed.
 * @param {string} [dataStruct='default'] - The data structure format for retrieving the currency
 * ('pair', 'keyCode', or 'default').
 * @param {string} [separator=':'] - The separator used when 'pair' format is specified.
 * @return {string|Object} The processed currency value based on the specified data structure.
 */
function getCurrencyByCode(currency, dataStruct = 'default', separator = ':') {
  switch (dataStruct) {
    case 'pair': return getPairCurrencies(currency, separator).first;
    case 'keyCode': return currency.code;
    default: return currency.currency || currency;
  }
}

/**
 * Check when adding a new coin
 * @param {Array} currencyList - The list of currency objects to be sorted. Each object should have
 * a `currency` property.
 * @param {String} dataStruct
 * @param {String} separator
 * @return {Array|undefined} The sorted array of currencies or undefined if input is invalid or empty.
 */
export function sortCurrency(currencyList, dataStruct = 'default', separator = ':') {
  if (!currencyList || !isArray(currencyList) || currencyList.length === 0) return;
  const orderOfCoins = [
    CRYPTO_CURRENCIES.BTC,
    CRYPTO_CURRENCIES.ETH,
    CRYPTO_CURRENCIES.USDT_ERC20,
    CRYPTO_CURRENCIES.USDT_TRC20,
    CRYPTO_CURRENCIES.USDT_Polygon,
    CRYPTO_CURRENCIES.USDT_BEP20,
    CRYPTO_CURRENCIES.USDT_SOL,
    CRYPTO_CURRENCIES.USDC, // USDC_ERC20
    CRYPTO_CURRENCIES.USDC_ERC20,
    CRYPTO_CURRENCIES.USDC_Polygon,
    CRYPTO_CURRENCIES.USDC_BEP20,
    CRYPTO_CURRENCIES.USDC_SOL,
    CRYPTO_CURRENCIES.DAI,
    CRYPTO_CURRENCIES.ETH_BEP20,
    CRYPTO_CURRENCIES.TRX,
    CRYPTO_CURRENCIES.MATIC_Polygon,
    CRYPTO_CURRENCIES.BNB,
    CRYPTO_CURRENCIES.TON,
    CRYPTO_CURRENCIES.SOL,
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
  ];

  const known = [];
  const unknown = [];

  for (const el of currencyList) {
    const currency = getCurrencyByCode(el, dataStruct, separator) || '';
    if (orderOfCoins.find((item) => (item === currency))) {
      known.push(el);
    } else {
      unknown.push(el);
    }
  }

  known.sort((a, b) => {
    const currencyA = getCurrencyByCode(a, dataStruct, separator) || '';
    const currencyB = getCurrencyByCode(b, dataStruct, separator) || '';
    return orderOfCoins.indexOf(currencyA) - orderOfCoins.indexOf(currencyB);
  });

  unknown.sort((a, b) => {
    const currencyA = getCurrencyByCode(a, dataStruct, separator) || '';
    const currencyB = getCurrencyByCode(b, dataStruct, separator) || '';
    return currencyA.localeCompare(currencyB);
  });

  return [...known, ...unknown];
}
