import {errorMsg} from '@/utils/notification';
/**
 * Open external URL
 * @param {string} url
 * @param {string} target
 */
export function openExternalURL(url, target = '_self') {
  const win = window.open(url, target);
  if (win) win.focus();
  else errorMsg('Please allow popups for this website');
}
