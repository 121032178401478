<template>
  <div class="ex-authorization-form">
    <div v-if="$slots.default" class="ex-authorization-form__form">
      <slot />
    </div>
    <div v-if="$slots.message || message.value" class="ex-authorization-form__message">
      <slot name="message">
        <ex-alert :type="message.type">
          <div v-html="message.value" />
        </ex-alert>
      </slot>
    </div>
    <div class="ex-authorization-form__action">
      <slot name="action">
        <ex-button @mClick="$emit('mClick')" :disabled-button="disabledButton">
          <slot name="actionLabel" />
        </ex-button>
      </slot>
    </div>
  </div>
</template>

<script>
// util
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

// component
import ExButton from '@/components/authorization/ui/ex-button';
import ExAlert from '@/components/ex-alert';

export default {
  name: 'ExAuthorizationForm',
  components: {ExAlert, ExButton},
  props: {
    message: {
      type: Object,
      default: () => {
        return {
          value: '',
          type: 'danger',
        };
      },
      validator(value) {
        return checkExpectedKeys(['value', 'type'], value);
      },
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-authorization-form {
  &__form {
    padding-bottom: 12px;
  }
  &__message {
    padding-bottom: 40px;
  }
  :deep .ex-button {
    width: 100%;
  }
  @include mix--media('xs') {
    &__form {
      padding-bottom: 8px;
    }
    &__message {
      padding-bottom: 30px;
    }
  }
}
</style>
