import {ElNotification} from 'element-plus';

/**
 * @param {String} message
 * @param {String} [title]
 * @param {Number} [duration]
 */
export function successMsg(message, title = 'Success', duration = 10000) {
  // eslint-disable-next-line new-cap
  ElNotification.success({title, message, duration});
}

/**
 * @param {String} message
 * @param {String} [title]
 * @param {Number} [duration]
 */
export function errorMsg(message, title = 'Error', duration = 10000) {
  // eslint-disable-next-line new-cap
  ElNotification.error({title, message, duration});
}
