import BaseApi from '@/api/base';

export default new class ExchangeApi extends BaseApi {
  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.currencyPair
   * @param {number} data.type
   * @param {number} data.amount
   * @param {number} data.price
   * @return {Promise<void>}
   */
  createExchangeOtc(event, data) {
    return super.post(event, '/api/exchange/create-exchange-otc', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.currencyPair
   * @param {number} data.type
   * @param {number} data.amount
   * @return {Promise<void>}
   */
  createExchangeRfq(event, data) {
    return super.post(event, '/api/exchange/create-exchange-rfq', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.exchangeId
   * @return {Promise<void>}
   */
  cancelExchangeOrder(event, data) {
    return super.post(event, '/api/exchange/cancel-exchange-order', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.exchangeId
   * @return {Promise<void>}
   */
  acceptExchangeOrder(event, data) {
    return super.post(event, '/api/exchange/accept-exchange-order', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<void>}
   */
  counterPendingOrders(event) {
    return super.post(event, '/api/exchange/counter-pending-orders');
  }

  /**
   * @param {string} event
   * @return {Promise<void>}
   */
  fetchExchangePairs(event) {
    return super.post(event, '/api/exchange/fetch-otc-pairs');
  }
};
