import {
  FETCH_USER_PROFILE,
} from '@/constants/events/store/currentUser/actions.type';
import {
  ASSIGN_CRYPTO_BALANCE_TO_ADDRESS,
  REQUEST_USER_PERSONAL_DATA,
  REVOKE_USER_PERSONAL_DATA,
  UPDATE_USER_GDPR_POLICY_VERSION,
} from '@/constants/events/currentUser/actions.type';
import {FETCH_COUNTRY, FETCH_STATE} from '@/constants/events/KYC/actions.type';
import {
  CREATE_WITHDRAW_CRYPTO_MANUAL,
  CREATE_WITHDRAW_FIAT_MANUAL,
  FETCH_REQUEST,
} from '@/constants/events/requests/actions.type';
import {
  FETCH_FIAT_AUTO_WITHDRAWAL_LIST,
  INIT_SUBSCRIBE_SPAM_PRICE_AUTO_TRADING,
} from '@/constants/events/requests/actions.type';
import {AUTH} from '@/constants/events/cardPay/actions.type';
import {
  FETCH_REQUEST_HISTORY,
  FETCH_REQUEST_LAST_TRANSACTION,
} from '@/constants/events/transaction/actions.type';

export default {
  [UPDATE_USER_GDPR_POLICY_VERSION]: 'Failed to save the adopted version of GDPR',
  [INIT_SUBSCRIBE_SPAM_PRICE_AUTO_TRADING]: 'On price',
  [AUTH]: 'Authorization code is invalid',
  [FETCH_REQUEST_LAST_TRANSACTION]: 'The last transaction of the request was not found',
  [FETCH_REQUEST_HISTORY]: 'Request history not found',
  [FETCH_REQUEST]: 'Request not found',
  [FETCH_FIAT_AUTO_WITHDRAWAL_LIST]: 'Error getting data',
  [CREATE_WITHDRAW_CRYPTO_MANUAL]: 'An error occurred while creating the withdrawal request',
  [CREATE_WITHDRAW_FIAT_MANUAL]: 'An error occurred while creating the withdrawal request',
  [FETCH_USER_PROFILE]: 'Unrecognized error. Please update the page',
  [REQUEST_USER_PERSONAL_DATA]: 'Request personal data is failed',
  [REVOKE_USER_PERSONAL_DATA]: 'Revoke personal data is failed',
  [ASSIGN_CRYPTO_BALANCE_TO_ADDRESS]: 'assign-crypto-balance-to-address',
  [FETCH_COUNTRY]: 'Unable to get list of countries',
  [FETCH_STATE]: 'Unable to get list of states',
};
