// util
import {isObject} from 'lodash';

// const
import {SOMETHING_WENT_WRONG_MESSAGE} from '@/constants/commonMessage';
import {CRITICAL_ERRORS_CODES} from '@/constants/common';
import errorList from '@/utils/socketError/errors';

import {
  DEFAULT_MSG_NO_ISSUE_ID,
  DEFAULT_MSG_EXCLUDED,
  DEFAULT_MSG_ISSUE_ID,
} from '@/utils/socketError/errorSources/defaultErrors';
import {errorMsg} from '@/utils/notification';

// setting
const INTERNAL_ERROR_CODE = 100;

/**
 * Class for handling messages containing error data
 */
export default class ErrorNotifier {
  static _errorList = Object.assign({}, errorList);

  /**
   * @param {Object} error
   * @param {string} error.code
   * @param {string} error.issueID
   * @param {string} error.message
   * @param {Array} error.validate
   * @param {string} event
   * @return {string|*}
   */
  static getMessage({error}, event) {
    const {prefix, code} = this.parseDirtyCode(error.code);
    if (code === 0) return this.getIssueIdMessage(error);
    let message = '';
    if (!prefix) {
      message = SOMETHING_WENT_WRONG_MESSAGE;
    } else if (!code) {
      message = SOMETHING_WENT_WRONG_MESSAGE;
    } else if (!this._errorList[prefix]) {
      message = SOMETHING_WENT_WRONG_MESSAGE;
    } else if (!this._errorList[prefix][code]) {
      message = SOMETHING_WENT_WRONG_MESSAGE;
    }
    if (INTERNAL_ERROR_CODE === code) {
      const validateObj = this.getValidations({error});
      if (validateObj.length) return this.getValidationMessage(validateObj[0]);
    }
    if (CRITICAL_ERRORS_CODES.includes(code)) {
      switch (code) {
        case 550: return 'Access from your country is restricted';
        default: return 'Your session has expired';
      }
    }
    if (message && event) { // default message by event
      return this._errorList.DEFAULT_ERROR_BY_EVENT[event] ? this._errorList.DEFAULT_ERROR_BY_EVENT[event] : message;
    }
    let messageByEvent = undefined;
    if (
      event && this._errorList.ERROR_BY_EVENT[event]
      && this._errorList.ERROR_BY_EVENT[event][prefix]
      && this._errorList.ERROR_BY_EVENT[event][prefix][code]
    ) {
      // TODO Add a unit test to check for an error from the list of errors by event in the general list of errors
      messageByEvent = this._errorList.ERROR_BY_EVENT[event][prefix][code];
    }
    return messageByEvent ? messageByEvent : this._errorList[prefix][code];
  }

  /**
   * @param {Object} validationItem
   * @param {string} validationItem.name
   * @param {string} validationItem.message
   * @param {number} validationItem.code
   * @return {string}
   */
  static getValidationMessage(validationItem) {
    const {name, defaultMessage, code} = this.getValidationData(validationItem);
    const errorList = this._errorList.VALIDATION_ERROR;
    if (isObject(errorList[code]) && name) {
      return errorList[code][name] || errorList[code].default || defaultMessage || SOMETHING_WENT_WRONG_MESSAGE;
    }
    return defaultMessage || SOMETHING_WENT_WRONG_MESSAGE;
  }

  /**
   * @param {Object} item
   * @param {string} item.name
   * @param {string} item.message
   * @param {number} item.code
   * @return {{defaultMessage, code, name}}
   */
  static getValidationData(item) {
    return {name: item.name, defaultMessage: item.message, code: item.code};
  }

  /**
   * @param {Object} error
   * @param {string} error.code
   * @param {string} error.issueID
   * @param {string} error.message
   * @param {Array} error.validate
   * @return {[]}
   */
  static getValidations({error}) {
    return error.validate || [];
  }

  /**
   * @param {Object} error
   * @param {string} error.code
   * @param {string} error.issueID
   * @param {string} error.message
   * @param {Array} error.validate
   * @param {string} event
   */
  static showErrorMessage({error}, event) {
    if (this.isExcludedErrorMessageByEvent({error}, event)) return;
    const message = this.getMessage({error}, event);
    if (DEFAULT_MSG_EXCLUDED === message) return;
    errorMsg(message);
  }

  /**
   * @param {Object} error
   * @param {string} error.code
   * @param {string} error.issueID
   * @param {string} error.message
   * @param {string} error.message
   * @param {Array} error.validate
   * @param {string} event
   * @return {boolean}
   */
  static isExcludedErrorMessageByEvent({error}, event) {
    if (!event) return false;
    const {prefix, code} = this.parseDirtyCode(error.code);
    if (!prefix || !code) return false;
    if (this._errorList.EXCLUDED_ERROR_BY_EVENT[event] === 'all') return true;
    if (this._errorList.EXCLUDED_ERROR_BY_EVENT[event] && this._errorList.EXCLUDED_ERROR_BY_EVENT[event][prefix]) {
      return this._errorList.EXCLUDED_ERROR_BY_EVENT[event][prefix].includes(code);
    }
    return undefined;
  }

  /**
   * @param {string} issueID
   * @return {string}
   */
  static getIssueIdMessage({issueID}) {
    if (!issueID) return DEFAULT_MSG_NO_ISSUE_ID;
    return DEFAULT_MSG_ISSUE_ID + issueID;
  }

  /**
   * @param {string} dirtyCode
   * @param {string} separator
   * @return {{
   * code: string,
   * prefix: string,
   * }}
   * @private
   */
  static parseDirtyCode(dirtyCode, separator = ':') {
    const result = dirtyCode.split(separator);
    return {
      prefix: result[0] ? Number(result[0]) : undefined,
      code: (result[1] || result[1] === '0') ? Number(result[1]) : undefined,
    };
  }

  /**
   * @param {String} dirtyCode
   * @return {boolean}
   */
  static isCriticalError(dirtyCode) {
    let resultCode;
    if (typeof dirtyCode === 'string' && dirtyCode.indexOf(':') !== -1) {
      const {code} = this.parseDirtyCode(dirtyCode);
      if (code) {
        resultCode = code;
      }
    } else {
      resultCode = dirtyCode;
    }
    return CRITICAL_ERRORS_CODES.includes(resultCode);
  }
}
