<template>
  <div class="ex-button">
    <button
      v-bind="$attrs"
      class="ex-button__button"
      :class="additionalClasses"
      :disabled="disabledButton"
      @click="$emit('mClick')"
    >
      <slot />
    </button>
    <div v-if="withGradient" class="ex-button__gradient" />
  </div>
</template>

<script>
export default {
  name: 'ExButton',
  props: {
    type: {
      type: String,
      default: 'flat',
    },
    size: {
      type: String,
      default: 'md',
    },
    color: {
      type: String,
      default: 'primary',
    },
    withGradient: {
      type: Boolean,
      default: false,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    additionalClasses() {
      let classes = `ex-button__button_size-${this.size}`;
      classes += ` ex-button__button_color-${this.color}`;
      classes += ` ex-button__button_type-${this.type}`;

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-button {
  position: relative;
  display: inline-block;
  &__button {
    border-radius: $--radius-st;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 28px;
    padding-right: 28px;
    position: relative;
    z-index: 1;
    width: 100%;
    outline: none;
    transition: background 0.2s;

    // type
    &_type-flat {}
    &_type-text {
      border-width: 0 !important;
      background: transparent !important;
      color: $--color-auth-typo-link !important;
      &:hover {
        color: $--color-auth-typo-link-active !important;
      }
      &:active {
        color: $--color-auth-typo-link !important;
      }
    }

    // size
    &_size-sm {
      @include mix--typo-button-sm;
      height: 47px;
    }
    &_size-md {
      @include mix--typo-button;
      height: 55px;
    }

    // color
    &_color-primary {
      border: 2px solid $--color-auth-secondary;
      color: $--color-auth-white;
      background: $--color-auth-primary;
      &:hover {
        border-color: $--color-auth-secondary-l1;
        background: $--color-auth-primary-l1;
      }
      &:active {
        border-color: $--color-auth-secondary;
        background: $--color-auth-primary;
      }
    }
    &_color-accent {
      border-width: 0;
      color: $--color-auth-typo-regular;
      background: $--color-auth-accent;
      &:hover {
        background: $--color-auth-accent-l1;
      }
      &:active {
        background: $--color-auth-accent;
      }
    }
  }

  // with gradient
  &__gradient {
    background: $--color-auth-accent-gradient;
    border-radius: $--radius-st;
    box-shadow: 0 7px 12px rgba(0, 124, 255, 0.05);
    filter: blur(21px);
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translateX(-50%);
    height: 70%;
    width: 50%;
    transition: background 0.2s;
  }
}
</style>
