export const settingsKeeper = (function() {
  let instance;

  /** */
  class SettingsKeeper {
    /**
     * @param {Object} socket
     */
    constructor(socket = null) {
      this.socket = socket;
    }

    /**
     * @param {string} socketId
     */
    set socketId(socketId) {
      this._socketId = socketId;
    }

    /**
     * @return {string}
     */
    get socketId() {
      return this._socketId;
    }

    /**
     * @param {boolean} socketState
     */
    set socketConnected(socketState) {
      this._socketConnected = socketState;
    }

    /**
     * @return {boolean}
     */
    get socketConnected() {
      return this._socketConnected;
    }

    /** */
    updateSocketId() {
      this._socketId = this.socket.io?.id;
      this._socketConnected = !!this._socketId;
    }

    /**
     * @param {Object} data
     * @param {String} data.accessId
     * @param {String} data.token
     * @param {Object} data.objects
     */
    subscribeBasicSubscriptions(data) {
      this.socket.io?.emit('basicSubscribe', data);
    }

    /** */
    reInitSocket() {
      this.socket.io?.close();
      this.socket.io?.connect();
    }
  }

  return {
    init: function(socket = null) {
      if (!instance && !socket) throw new Error('SettingsKeeper not initialized');
      if (!instance) instance = new SettingsKeeper(socket);
      return instance;
    },
  };
})();
