import getters from '@/store/getters';
import auth from '@/store/modules/auth';
import currentUser from '@/store/modules/currentUser';
import requests from '@/store/modules/requests';
import merchantInvoice from '@/store/modules/merchantInvoice';
import content from '@/store/modules/content';
import appState from '@/store/modules/appState';
import exchange from '@/store/modules/exchange';
import {createStore} from 'vuex';

const store = createStore( {
  getters,
  modules: {auth, currentUser, requests, merchantInvoice, content, appState, exchange},
});

export default store;
