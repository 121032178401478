// guard
import {notAuthBehavior} from '@/routes/authRouters';

// component
import ExRegistration from '@/pages/registration/ex-registration.vue';
import ExLogin from '@/pages/login/ex-login.vue';
import ExLoginCredential from '@/pages/login/ex-login-credential.vue';
import ExLoginSelectType from '@/pages/login/ex-login-select-type.vue';
import ExLogin2fa from '@/pages/login/ex-login-2fa.vue';
const ExIdenfyResult = () => import('@/pages/onBoarding/ex-idenfy-result');
const ExRecoveryValidation = () => import('@/pages/recoveryAccount/ex-recovery-validation');

export default (store) => {
  return [
    {
      path: '/verification-idenfy/success',
      component: ExIdenfyResult,
      name: 'idenfy-success',
      props: () => ({type: 'success'}),
    },
    {
      path: '/verification-idenfy/error',
      component: ExIdenfyResult,
      name: 'idenfy-error',
      props: () => ({type: 'error'}),
    },
    {
      path: '/deposit/success/:key',
      component: () => import('@/pages/deposit/fiat/ex-deposit-success'),
      name: 'DepositSuccess',
      beforeEnter: notAuthBehavior,
      meta: {store},
    },
    {
      path: '/deposit/failure/:key',
      component: () => import('@/pages/deposit/fiat/ex-deposit-failure'),
      name: 'DepositFailure',
      beforeEnter: notAuthBehavior,
      meta: {store},
    },
    {
      path: '/deposit/result/:key',
      component: () => import('@/pages/deposit/fiat/ex-deposit-result'),
      name: 'DepositResult',
      beforeEnter: notAuthBehavior,
      meta: {store},
    },
    {
      path: '/signup',
      component: ExRegistration,
      beforeEnter: notAuthBehavior,
      name: 'UserRegistration',
      meta: {store},
    },
    {path: '/signup/confirm/email/:token/:code', component: ExRegistration, name: 'UserRegistrationByEmailLink'},
    {
      path: '/login',
      component: ExLogin,
      beforeEnter: notAuthBehavior,
      meta: {store},
      children: [
        {path: '', component: ExLoginCredential, name: 'LoginCredential'},
        {path: 'type', component: ExLoginSelectType, name: 'LoginSelectType'},
        {path: '2fa', component: ExLogin2fa, name: 'Login2FA'},
      ],
    },
    {
      path: '/recovery',
      component: () => import('@/pages/recoveryAccount/ex-recovery'),
      beforeEnter: notAuthBehavior,
      meta: {store},
      children: [
        {
          path: '',
          component: () => import('@/pages/recoveryAccount/ex-recovery-identification'),
          name: 'RecoveryIdentification',
        },
        {path: 'validation', component: ExRecoveryValidation, name: 'RecoveryValidation'},
        {
          path: 'password',
          component: () => import('@/pages/recoveryAccount/ex-recovery-new-password'),
          name: 'RecoveryNewPassword',
        },
        {path: 'validation/email/:token/:code', component: ExRecoveryValidation, name: 'RecoveryByLink'},
      ],
    },
  ];
};
