import Inputmask from 'inputmask';
import {
  isNineSignCurrency,
  isSixSignsCurrency,
  isTwoSignsCurrency,
} from '@/utils/converters/currency';

const TYPICAL_SETUP = {
  groupSeparator: ',',
  placeholder: '0',
  allowMinus: false,
  autoGroup: true,
  digitsOptional: true,
  rightAlign: false,
};

const inputmasks = {
  // TODO Temporarily hiding fiat auto withdrawal creation form
  // cardNumber() {
  //   return new Inputmask({
  //     mask: ['X999 9999 9999 9[999]'],
  //     jitMasking: true,
  //     definitions: {
  //       'X': {
  //         validator: '[4-5]',
  //       },
  //     },
  //     onBeforeMask(value) {
  //       return value;
  //     },
  //     onUnMask(value) {
  //       return value;
  //     },
  //   });
  // },
  addMaskByCurrency(currency, ref, min = undefined, max = undefined) {
    const refExtended = ref.$el.getElementsByTagName('INPUT')[0];
    this[getMaskTypeByCurrency(currency)].call(ref, min, max).mask(refExtended);
  },
  recipientId() {
    return new Inputmask({
      mask: ['INTuuuuuuuuTR'],
      jitMasking: true,
      definitions: {
        I: {
          validator: '[I]',
        },
        N: {
          validator: '[N]',
        },
        T: {
          validator: '[T]',
        },
        R: {
          validator: '[R]',
        },
        u: {
          validator: '[0-9A-Za-z]',
        },
      },
      onBeforeMask(value) {
        return value;
      },
      onUnMask(value) {
        return value;
      },
    });
  },
  twoSigns(min = undefined, max = undefined) {
    return new Inputmask('numeric', {...TYPICAL_SETUP, min, max, digits: 2});
  },
  sixSigns(min = undefined, max = undefined) {
    return new Inputmask('numeric', {...TYPICAL_SETUP, min, max, digits: 6});
  },
  eightSigns(min = undefined, max = undefined) {
    return new Inputmask('numeric', {...TYPICAL_SETUP, min, max, digits: 8});
  },
  nineSigns(min = undefined, max = undefined) {
    return new Inputmask('numeric', {...TYPICAL_SETUP, min, max, digits: 9});
  },
};

/**
 * @param {String} currency
 * @return {String}
 */
export function getMaskTypeByCurrency(currency) {
  return isNineSignCurrency(currency) ? 'nineSigns'
    : isSixSignsCurrency(currency) ? 'sixSigns'
      : isTwoSignsCurrency(currency) ? 'twoSigns'
        : 'eightSigns';
}

export default inputmasks;
