// const
import {VERIFICATION_STATUSES_COMPANY, VERIFICATION_STATUSES_INDIVIDUAL} from '@/constants/verification';
import {errorMsg} from '@/utils/notification';
import {ROLE_GENERAL} from '@/constants/roles';

export const notAuthBehavior = (to, from, next) => {
  const store = to.meta.store;
  if (store.state.auth.isLoggedIn) return next({name: 'Home'});
  next();
};

export const accIsNotVerified = async (to, from, next) => {
  const store = to.meta.store;
  if (isNotAuth(store)) return next({name: 'LoginCredential', query: {redirect: to.fullPath}});
  if (!store.getters['currentUser/userIsReady']) {
    store.commit('appState/guardStartAwaitData');
    await store.dispatch('currentUser/awaitUserProfileAndRoles');
    store.commit('appState/guardStopAwaitData');
  }
  const verification = store.state.currentUser.profile.verification;
  const isCompany = store.getters['auth/isCompany'];
  const verificationCodes = isCompany ? VERIFICATION_STATUSES_COMPANY.VALUE : VERIFICATION_STATUSES_INDIVIDUAL.VALUE;
  const roles = store.state.currentUser.roles;
  if (roles.length && verification === verificationCodes.GENERAL) {
    errorMsg(
      `You do not have access! ${isCompany ? 'Company' : 'Personal'} account verified`,
      'Forbidden',
    );
    return next({name: 'Home'});
  }
  next();
};

export const authBehavior = async (to, from, next) => {
  const store = to.meta.store;
  if (isNotAuth(store)) return next({name: 'LoginCredential', query: {redirect: to.fullPath}});
  if (['idenfy-success', 'idenfy-error'].includes(to.name)) return next();
  if (!store.getters['currentUser/userIsReady']) {
    store.commit('appState/guardStartAwaitData');
    await store.dispatch('currentUser/awaitUserProfileAndRoles');
    store.commit('appState/guardStopAwaitData');
  }
  const verification = store.state.currentUser.profile.verification;
  const verificationByUserType =
    store.getters['auth/isCompany'] ? VERIFICATION_STATUSES_COMPANY.VALUE : VERIFICATION_STATUSES_INDIVIDUAL.VALUE;
  let name = null;
  if (verification <= verificationByUserType.WAITING) name = 'OnBoarding';
  if (name === from.name || from.name === to.name) return;
  if (name) return next({name});
  const roles = store.state.currentUser.roles || [];
  if (verification === verificationByUserType.GENERAL && roles.includes(ROLE_GENERAL)) return next();
  errorMsg('You do not have access! Verification needed.', 'Forbidden');
  next(false);
  return store.dispatch('auth/logout');
};

/**
 * @param {Object} store
 * @return {boolean}
 */
function isNotAuth(store) {
  const isLoggedIn = store.state.auth.isLoggedIn;
  const currentTokenDuration = store.state.auth.authExpire - Date.now();
  const checkDelay = (30 * 1000);
  return !isLoggedIn || currentTokenDuration < checkDelay;
}

export const additionalRole = (to, from, next) => {
  const store = to.meta.store;
  const role = to.meta.role;
  const roles = store.state.currentUser.roles || [];
  if (roles.includes(role)) return next();
  errorMsg('You do not have access! Verification needed.', 'Forbidden');
  next({name: 'Home'});
};
