// app
import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
import {router} from '@/main';

// util
import {AuthSessionStorage} from '@/utils/web-storage/session';
import {settingsKeeper} from '@/utils/settingsKeeper';

// const
import {SOMETHING_WENT_WRONG_MESSAGE} from '@/constants/commonMessage';
import {errorMsg} from '@/utils/notification';

let socketSetting;

/***/
export default class BaseApi {
  /**
   * @param {number} ms
   * @return {Promise<*>}
   */
  static async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * @param {string} socketEvent
   * @return {Promise<*>}
   */
  static async getRequestSettings(socketEvent) {
    if (!socketSetting) socketSetting = settingsKeeper.init();
    const loop = async () => {
      if (!socketSetting.socketConnected) {
        await BaseApi.sleep(100);
        return loop();
      }
      return true;
    };
    return loop().then(() => ({
      settings: {
        callback: socketEvent,
        socketToken: socketSetting.socketId,
        token: AuthSessionStorage.auth ? AuthSessionStorage.auth.token : '',
        accessId: AuthSessionStorage.auth ? AuthSessionStorage.auth.accessId : '',
      },
    }));
  }

  /**
   * @return {Promise<{headers: {'Access-Id': (*|string)}}>}
   */
  static async defaultOptions() {
    return {headers: {'Access-Id': AuthSessionStorage.auth ? AuthSessionStorage.auth.accessId : ''}};
  }

  /**
   * @param {string} socketEvent
   * @param {string} url
   * @param {any} [data]
   * @param {any} [options]
   * @return {Promise<T>}
   */
  async post(socketEvent, url, data, options = {}) {
    data = Object.assign({}, data, await BaseApi.getRequestSettings(socketEvent));
    return axios.post(url, data, {...await BaseApi.defaultOptions(), ...options})
      .then((res) => res)
      .catch(BaseApi.baseRequestErrorHandler);
  }

  /**
   * @param {Object} e
   * @return {Promise<*>}
   */
  static async baseRequestErrorHandler(e) {
    // user denied access
    if (e?.response?.data?.error?.code === 403 && e?.response?.status === 403) {
      errorMsg('You do not have access! Verification needed.', 'Forbidden');
    } else if (e.name === 'AxiosError') errorMsg(SOMETHING_WENT_WRONG_MESSAGE);
    // the user is blocked
    if (e?.response?.data?.error?.code === 1 && e?.response?.status === 403) {
      router.push({name: 'ErrorPage', params: {error: 403}});
    }
    throw e;
  }
}
