<template>
  <div v-loading="isLoading" class="ex-login-2fa-sms">
    <ex-one-input-form
      :message="message"
      sub-title="Please verify your identity"
      label="Code from SMS"
      placeholder="Type code from SMS"
      action-label="Confirm"
      :code-expire="codeExpire"
      :disabled-button="isLoading"
      @mClick="verifyCode"
      @resend="reRequestSmsCode"
    />
  </div>
</template>

<script>
// component
import ExOneInputForm from '@/components/authorization/layout/ex-one-input-form';

// util
import {socketRequest} from '@/utils/socket';
import {checkExpectedKeys} from '@/utils/validatorPropObjects';
import {loginSessionStorage} from '@/utils/web-storage/session';

// api
import AuthorizationApi from '@/api/authorization.api';

// const
import {CODE_AUTH_SESSION_TIME_LIVED} from '@/constants/common';
import {
  REQUEST_SMS_CODE_LOGIN,
  RE_REQUEST_CODE_LOGIN,
  VERIFY_SMS_CODE_LOGIN,
} from '@/constants/events/login/actions.type';

export default {
  name: 'ExLogin2faSms',
  components: {ExOneInputForm},
  props: {
    authorizationProfile: {
      type: Object,
      required: true,
      validator: (authorizationProfile) => checkExpectedKeys(['userId'], authorizationProfile.external)
        && checkExpectedKeys(['selectedAccount'], authorizationProfile),
    },
  },
  data() {
    return {
      message: '',
      codeExpire: 0,
      isLoading: false,
    };
  },
  created() {
    if (loginSessionStorage.getSmsExpire() > Date.now()) {
      this.codeExpire = loginSessionStorage.getSmsExpire();
    } else {
      loginSessionStorage.setSmsExpire(undefined);
      this.requestSmsCode();
    }
  },
  methods: {
    requestSmsCode() {
      this.message = '';
      socketRequest.call(this, REQUEST_SMS_CODE_LOGIN,
        (payload) => {
          const codeExpire = (payload.expire + payload.timestamp) * 1000;
          this.codeExpire = codeExpire;
          loginSessionStorage.setSmsExpire(codeExpire);
          return 'SMS code sent successfully';
        },
        (payload) => {
          if (payload.error.code === CODE_AUTH_SESSION_TIME_LIVED) {
            this.$emit('resetLogin', payload.handledErrorMessage);
          } else if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      const userId = this.authorizationProfile.external.userId;
      const organizationId = this.authorizationProfile.selectedAccount;
      AuthorizationApi.requestCode(REQUEST_SMS_CODE_LOGIN, {
        authType: 'SMS',
        userId,
        organizationId: userId !== organizationId ? organizationId : undefined,
      });
    },
    reRequestSmsCode() {
      this.message = '';
      socketRequest.call(this, RE_REQUEST_CODE_LOGIN,
        (payload) => {
          const codeExpire = (payload.expire + payload.timestamp) * 1000;
          this.codeExpire = codeExpire;
          loginSessionStorage.setSmsExpire(codeExpire);
          return 'SMS code sent successfully';
        },
        (payload) => {
          if (payload.error.code === CODE_AUTH_SESSION_TIME_LIVED) {
            this.$emit('resetLogin', payload.handledErrorMessage);
          } else if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      AuthorizationApi.reRequestCode(RE_REQUEST_CODE_LOGIN, {userId: this.authorizationProfile.external.userId});
    },
    verifyCode(code) {
      socketRequest.call(this, VERIFY_SMS_CODE_LOGIN,
        () => {
          this.$emit('smsStageCompleted');
        },
        (payload) => {
          if (payload.error.code === CODE_AUTH_SESSION_TIME_LIVED) {
            this.$emit('resetLogin', payload.handledErrorMessage);
          } else if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.message = '';
      this.isLoading = true;
      AuthorizationApi.verifyCode(VERIFY_SMS_CODE_LOGIN, {
        code,
        authType: 'SMS',
        userId: this.authorizationProfile.external.userId,
      });
    },
  },
};
</script>
