// eslint-disable-next-line no-unused-vars
import {App, VueRouter} from 'vue';

// mixin
import NotificationsMixin from '@/mixins/NotificationMixin';

// external package
import ElementPlus, {ElMessageBox} from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import VueClipboard from 'vue3-clipboard';

// util
import inputmasks from '@/utils/inputmasks';
import InitSocketErrorHandler from '@/utils/socketError/socketErrorHandler';

// config
import getSocketConfig from '@/config/socket';
import {settingsKeeper} from '@/utils/settingsKeeper';

/**
 * @param {App} app
 */
function setUpMixin(app) {
  app.mixin(NotificationsMixin);
}

/**
 * @param {App} app
 */
function setUpExternalPackage(app) {
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
  app.use(ElementPlus, {size: 'default', zIndex: 3000});
  app.use(VueClipboard, {});
}

/**
 * @param {VueRouter} router
 */
function setUpRoutesConfig(router) {
  router.beforeEach((to, from, next) => {
    if (!to.matched.length) return next('/error/404');
    next();
  });
}

/**
 * @param {App} app
 * @param {Object} store
 */
function setSocketConfig(app, store) {
  const vueSocketIO = getSocketConfig(store);
  settingsKeeper.init(vueSocketIO);
  new InitSocketErrorHandler(vueSocketIO);
  app.use(vueSocketIO);
}

/**
 * @param {App} app
 * @param {Store} store
 * @param {VueRouter} router
 */
export default function(app, store, router) {
  setSocketConfig(app, store);
  setUpRoutesConfig(router);
  setUpExternalPackage(app);
  setUpMixin(app);
}

export const confirmDirective = {
  mounted(el, binding) {
    el.addEventListener('click', () => {
      const {message, okText, cancelText, ok, cancel, showClose, closeOnClickModal} = binding.value || {};
      ElMessageBox
        .confirm(message || 'Are you sure you want to continue?',
          {
            confirmButtonText: okText || 'Continue',
            cancelButtonText: cancelText || 'Cancel',
            showClose: showClose || false,
            closeOnClickModal: closeOnClickModal === true,
            buttonSize: 'default',
          },
        )
        .then(() => {
          if (ok) ok();
        })
        .catch(() => {
          if (cancel) cancel();
        });
    });
  },
};

export const maskDirective = {
  mounted(el, binding) {
    const element = el.tagName === 'INPUT' ? el : el.getElementsByTagName('INPUT')[0];
    const inputmask = inputmasks[binding.arg] || function() {
      return {mask: () => {}};
    };
    inputmask().mask(element);
  },
};
