<template>
  <div class="ex-dialog">
    <el-dialog
      v-bind="$attrs"
      :title="title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :center="true"
      :width="width"
    >
      <div style="text-align: center; word-break: break-word;"><slot /></div>
      <template #footer><slot name="footer" /></template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ExDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '50%',
    },
  },
};
</script>

<style lang="scss">
.ex-dialog {
  .el-dialog {
    border-radius: 24px;
    &__title {
      @include mix--typo-heading4;
    }
    &__body {
      @include mix--typo-body0;
    }
    @include mix--media('xs') {
      width: 300px !important;
      &__body {
        @include mix--typo-body1;
      }
    }
  }
}
</style>
