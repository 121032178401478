<template>
  <div class="ex-tel-input">
    <vue-tel-input v-bind="$attrs" />
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: 'ExTelInput',
  components: {VueTelInput},
};
</script>

<style lang="scss" scoped>
.ex-tel-input{
  width: 100%;
  :deep {
    .vue-tel-input {
      border: 1px solid  var(--el-input-border-color, var(--el-border-color));
      border-radius: $--radius-st;
      height: 64px;
      transition: border-colos 0.3s;
    }
    .vti__phone {
      border-radius: $--radius-st;
      font-size: 18px;
      color: var(--el-input-text-color, var(--el-text-color-regular));
      transition: border-colos 0.3s;
      font-family: "Roboto Condensed", sans-serif;
    }
    .vti__dropdown {
      transition: border-colos 0.3s;
      border-radius: $--radius-st;
    }
    ::placeholder {
      color: $--color-auth-typo-caption;
    }
  }
}
</style>
