// Application imports
import App from '@/App';
import routePreparer from '@/routes';
import store from '@/store';
import {createApp} from 'vue';

// settings
import setting, {confirmDirective, maskDirective} from '@/config/setting';

// eslint-disable-next-line
console.log('ENVIRONMENT', process.env.NODE_ENV);
const app = createApp(App);
app.use(store);
export const router = routePreparer(store);

setting(app, store, router);
app.use(router);

// directive
app.directive('confirm', confirmDirective);
app.directive('mask', maskDirective);

app.mount('#app');
