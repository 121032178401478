<template>
  <div class="ex-sidebar">
    <el-menu
      v-if="hasGeneralRole && !isHideByRoutes"
      :default-active="$route.path"
      unique-opened
      router
    >
      <el-menu-item :index="$router.resolve({name: 'BalancesIndex'}).href" class="ex-sidebar__item">
        <i class="ex-sidebar__icon fa-solid fa-balance-scale" />
        <span slot="title">Balances</span>
      </el-menu-item>
      <el-menu-item
        :key="ordersKey"
        :index="ordersRef"
        class="ex-sidebar__item"
      >
        <i class="ex-sidebar__icon fa-solid fa-university" />
        <template #title>
          <el-badge v-if="counterPendingOpenOrders" :value="counterPendingOpenOrders" :offset="[11, 10]">
            Orders
          </el-badge>
          <span v-else>Orders</span>
        </template>
      </el-menu-item>
      <el-menu-item :index="$router.resolve({name: 'Transaction'}).href" class="ex-sidebar__item">
        <i class="ex-sidebar__icon fa-solid fa-exchange" />
        <span slot="title">Transaction</span>
      </el-menu-item>
<!--      TODO Uncomment when transition to Tunnel is ready-->
<!--      <el-menu-item-->
<!--        v-if="hasMerchantRole"-->
<!--        :index="$router.resolve({name: 'MerchantInvoices'}).href"-->
<!--        class="ex-sidebar__item"-->
<!--      >-->
<!--        <i class="ex-sidebar__icon fa-solid fa-credit-card" />-->
<!--        <span slot="title">Envoice</span>-->
<!--      </el-menu-item>-->
      <el-menu-item :index="$router.resolve({name: 'WithdrawalList'}).href" class="ex-sidebar__item">
        <i class="ex-sidebar__icon fa-solid fa-arrow-up"/>
        <span slot="title">Withdrawals</span>
      </el-menu-item>
      <el-menu-item
        v-if="hasBillingRole && gateway"
        :index="gatewayRef"
        :key="gatewayKey"
        class="ex-sidebar__item"
      >
        <i class="ex-sidebar__icon fa-solid fa-desktop" />
        <span slot="title">Gateway</span>
      </el-menu-item>
      <el-menu-item
        v-if="hasInternalTransferRole"
        :index="$router.resolve({name: 'InternalTransferCreate'}).href"
        class="ex-sidebar__item"
      >
        <i class="ex-sidebar__icon fa-solid fa-people-arrows" />
        <span slot="title">Internal Transfer</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

// setting
const gatewayRoutes = ['GatewayOutgoing', 'GatewayIncoming'];

export default {
  name: 'ExSidebar',
  data() {
    const defaultGatewayRef = this.$router.resolve({name: 'GatewayIncoming'}).href;
    const defaultOrdersRef = this.$router.resolve({name: 'Orders', params: {type: 'open-orders'}}).href;
    return {
      gatewayKey: Symbol('gateway'),
      gatewayRef: defaultGatewayRef,
      defaultGatewayRef,
      ordersKey: Symbol('orders'),
      ordersRef: defaultOrdersRef,
      defaultOrdersRef,
    };
  },
  computed: {
    ...mapGetters('currentUser', [
      'hasGeneralRole',
      'hasBillingRole',
      'hasMerchantRole',
      'hasInternalTransferRole',
    ]),
    ...mapState('exchange', ['counterPendingOpenOrders']),
    ...mapState('content', {gateway: (state) => state.content.availableSections?.gateway}),
    isHideByRoutes() {
      return ['ErrorPage'].includes(this.$route.name);
    },
  },
  watch: {
    '$route.name': {
      handler(newValue, oldValue) {
        const href = this.$router.resolve({name: newValue}).href;
        if (gatewayRoutes.includes(newValue) && href !== this.gatewayRef) {
          this.gatewayRef = href;
          this.updateGatewayKey();
        }
        if (newValue !== 'Orders' && oldValue === 'Orders' && this.ordersRef !== this.defaultOrdersRef) {
          this.ordersRef = this.defaultOrdersRef;
          this.updateOrderKey();
        }
        if (
          !gatewayRoutes.includes(newValue)
          && gatewayRoutes.includes(oldValue)
          && this.gatewayRef !== this.defaultGatewayRef
        ) {
          this.gatewayRef = this.defaultGatewayRef;
          this.updateGatewayKey();
        }
      },
      immediate: true,
    },
    '$route.params.type': {
      handler(value) {
        const href = this.$router.resolve({name: this.$route.name, params: value}).href;
        if (this.$route.name === 'Orders' && href !== this.ordersRef) {
          this.ordersRef = href;
          this.updateOrderKey();
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateOrderKey() {
      this.ordersKey = Symbol('orders');
    },
    updateGatewayKey() {
      this.gatewayKey = Symbol('gateway');
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-sidebar {
  @media (max-width: 1280px) {
    padding: 15px 10px 30px;
  }
  @media (max-width: 500px) {
    padding: 0 6px;
  }
  &__item {
    color: #8e8e93;
  }
  &__icon {
    padding-right: 12px;
  }
  :deep {
    .el-menu {
      @media (max-width: 1280px) {
        justify-content: center;
      }
      border: none;
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
    }
    .el-menu-item {
      font-size: 17px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      height: 38px;
      line-height: 38px;
      margin: 0 13px;
      @media (min-width: 1280px) {
        margin: 0 26px 3px 0;
        width: 170px;
      }
    }
    .el-menu-item.is-active {
      background: #eee;
    }
  }
}
</style>
