<template>
  <div id="app">
    <ex-splash-screen v-if="showSplashScreen" />
    <ex-layout v-else v-loading="!socketConnected" element-loading-text="Reconnection...">
      <template v-if="isNewLayout" #default>
        <ex-navbar />
        <ex-two-columns>
          <router-view />
        </ex-two-columns>
      </template>
      <template v-else #default>
        <nav-bar :show-sidebar="!extendedContainerWrapper" :user="profile" />
        <div class="content-wrapper" :class="{extended: extendedContainerWrapper}">
          <ex-sidebar />
          <main class="main-container">
            <router-view v-slot="{Component}">
              <ex-splash-screen v-if="!Component" />
              <transition name="main-section-fade">
                <component :is="Component"></component>
              </transition>
            </router-view>
          </main>
        </div>
      </template>
    </ex-layout>
  </div>
</template>

<script>
// app
import {mapActions, mapGetters, mapState} from 'vuex';

// mixin
import LogoutByTimeoutMixin from '@/mixins/LogoutByTimeoutMixin';

// component
import NavBar from '@/components/core/ex-navbar.vue';
import ExNavbar from '@/components/ex-navbar';
import ExTwoColumns from '@/layouts/ex-two-column';
import ExSidebar from '@/components/ex-sidebar';
import ExSplashScreen from '@/components/core/ex-splash-screen';
import ExLayout from '@/components/core/ex-layout';

// setting
const ROUTE_NAMES_WITH_NEW_LAYOUT = [
  'LoginCredential',
  'LoginSelectType',
  'Login2FA',
  'RecoveryIdentification',
  'RecoveryValidation',
  'RecoveryNewPassword',
  'RecoveryByLink',
  'UserRegistration',
  'UserRegistrationByEmailLink',
];

export default {
  name: 'App',
  components: {
    ExSplashScreen,
    ExLayout,
    ExSidebar,
    ExTwoColumns,
    ExNavbar,
    NavBar,
  },
  mixins: [LogoutByTimeoutMixin],
  computed: {
    ...mapState('auth', ['isLoggedIn']),
    ...mapState('appState', ['socketConnected', 'guardAwaitData']),
    ...mapGetters('currentUser', ['userIsReady']),
    ...mapState('currentUser', {
      hasUserRoles: (state) => state.meta.isReceived.roles,
      hasUserProfile: (state) => state.meta.isReceived.profile,
      profile: (state) => state.profile,
    }),
    showSplashScreen() {
      return this.isLoggedIn && (!this.userIsReady || this.guardAwaitData);
    },
    extendedContainerWrapper() {
      const extendedContainerWrapperRoutes = [
        'Home',
        'ErrorPage',
        'DepositSuccess',
        'DepositFailure',
        ...ROUTE_NAMES_WITH_NEW_LAYOUT,
      ];
      return extendedContainerWrapperRoutes.indexOf(this.$route.name) !== -1;
    },
    isNewLayout() {
      return ROUTE_NAMES_WITH_NEW_LAYOUT.includes(this.$route.name);
    },
  },
  created() {
    this.fetchBasicContent();
    if (this.isLoggedIn) {
      this.fetchPrimaryUserData();
      this.refreshAuthToken();
    }
  },
  methods: {
    ...mapActions('auth', ['refreshAuthToken']),
    ...mapActions('currentUser', ['fetchPrimaryUserData']),
    ...mapActions('content', ['fetchBasicContent']),
  },
};
</script>

<style lang="scss">
@import '@/styles/vendor';
@import '@/styles/app';
.el-button,
.el-input__inner,
.el-textarea__inner {
  font-family: "Roboto Condensed", sans-serif;
}
.main-section-fade-enter-active {
  transition: all .2s ease;
}
.main-section-fade-leave-active {
  height: 0;
}
.main-section-fade-enter,
.main-section-fade-leave-to {
  opacity: 0;
}
</style>
