<template>
  <div class="ex-login-select-account">
    <ex-authorization>
      <template #title>Join the {{brand}} Digital!</template>
      <template #subTitle>Please, select an organization</template>
      <ex-authorization-form @mClick="handleClick">
        <el-form :model="form" :rules="rules" ref="form" label-position="top">
          <el-form-item label="Select authorization as Individual or Corporate" prop="value">
            <el-select
              v-model="form.value"
              :fallback-placements="['bottom-start', 'top-start']"
              class="ex-login-select-account__select"
            >
              <el-option
                v-for="(item) in authorizationProfile.external.organizations"
                :key="item.id"
                :label="getAccountOptionLabel(item)"
                :value="item.id"
              >
                <span class="ex-login-select-account__select-option">
                  <span>{{ item.name }}</span>
                  <span v-show="item?.isSubAccount">{{ item?.clientId }}</span>
                </span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #actionLabel>Choose</template>
      </ex-authorization-form>
    </ex-authorization>
  </div>
</template>

<script>
// component
import ExAuthorization from '@/components/authorization/layout/ex-authorization-layout';
import ExAuthorizationForm from '@/components/authorization/layout/ex-authorization-form';

// util
import {ruleRequired} from '@/utils/elementPlusTypicalValidation';
import {checkExpectedKeys} from '@/utils/validatorPropObjects';
import {mapState} from 'vuex';
import {isObject} from 'lodash';

export default {
  name: 'ExLoginSelectType',
  components: {ExAuthorizationForm, ExAuthorization},
  props: {
    authorizationProfile: {
      type: Object,
      required: true,
      validator: (authorizationProfile) => checkExpectedKeys(['organizations'], authorizationProfile.external)
        && checkExpectedKeys(['numberOfAccounts'], authorizationProfile),
    },
  },
  created() {
    if (this.authorizationProfile.numberOfAccounts <= 1) {
      return this.$router.push({name: 'LoginCredential', query: this.$route.query});
    }
    if (this.authorizationProfile.numberOfAccounts > 0) {
      this.form.value = this.companiesList[0]?.id;
    }
  },
  data() {
    return {
      form: {
        value: '',
      },
      rules: {
        value: [ruleRequired],
      },
    };
  },
  computed: {
    ...mapState('content', {brand: (state) => state.content.brand}),
    companiesList() {
      if (!isObject(this.authorizationProfile.external.organizations)) return [];
      return Object.values(this.authorizationProfile.external.organizations);
    },
  },
  methods: {
    handleClick() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        const user = this.companiesList.find((item) => item.id === this.form.value);
        if (user.name !== 'individual') this.$emit('setSelectedAccount', this.form.value);
        this.$router.push({name: 'Login2FA', query: this.$route.query});
      });
    },
    getAccountOptionLabel(item) {
      let label = item.name;
      if (item?.isSubAccount && item?.clientId) label += ` (${item.clientId})`;
      return label;
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-login-select-account {
  &__select {
    width: 100%;
  }
  &__select-option {
    display: flex;
    justify-content: space-between;
  }
}
</style>
