<template>
  <div class="ex-balance">
    <teleport to="#ex-nav-bar__title">Balances</teleport>
    <div class="ex-balance__content">
      <div class="ex-balance__header">
        <div class="ex-balance__filters">
          <el-select v-model="currencyType" class="ex-balance__filters-select" placeholder="Filter currencies">
            <el-option v-for="item in optionsCurrencies" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <ex-report type="Balance" :value="{filters: {dateEnd: date}}">
            Get PDF Report
          </ex-report>
        </div>
        <el-form class="ex-balance__date" label-position="left">
          <el-form-item label="Historical Balance" class="ex-balance__form-item">
            <el-date-picker
              v-model="date"
              @change="applyTableFilter"
              placeholder="Select date and time"
              :default-time="new Date(2000, 1, 1, 12, 0, 0)"
              type="datetime"
            />
          </el-form-item>
          <el-switch
            v-loading="updatingZeroBalanceBehavior"
            :model-value="profile.hideZeroBalances"
            @change="toggleZeroBalance(!profile.hideZeroBalances)"
            active-text="Hide zero balance"
          />
        </el-form>
      </div>
      <el-table v-loading="isLoading" :data="prepBalance">
        <el-table-column label="Currency" min-width="80px">
          <template #header>
            <span class="ex-balance__first-column-label">Currency</span>
          </template>
          <template v-slot="{row}">
            <div class="ex-balance__currency">
              <div class="ex-balance__icon-section">
                <div class="ex-balance__icon-wrapper ex-coin-icon-size">
                  <img
                    :src="getCurrency(row?.currency).icon"
                    alt="icon"
                    :class="{
                      'ex-balance__icon_blur': SPECIAL_CURRENCIES_LIST.includes(row?.currency),
                      'ex-coin-icon-special-size': !getCurrency(row?.currency)?.iconWithNetwork,
                    }"
                  />
                </div>
              </div>
              <span>{{getCurrency(row?.currency).name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Amount" min-width="80px">
          <template v-slot="{row}">
            {{formatCurrency(row.amount, row.type, isNineSignCurrency(row.currency) ? 9 : null)}}
          </template>
        </el-table-column>
        <el-table-column min-width="150px">
          <template #header>
            <span class="ex-balance__third-column-label">Actions</span>
          </template>
          <template v-slot="{row}">
            <div v-if="row.type === CURRENCY_TYPE.CRYPTO" class="ex-balance__actions">
              <el-button
                class="ex-balance__action"
                type="warning"
                plain
                :disabled="CURRENCIES_DISCONNECTED_IN_BALANCES.includes(row.currency)"
                @click="
                  autoTrading
                  ? $router.push({name: 'TradingMode', params: {currency: row.currency, type: 'buy'}})
                  : $router.push({
                    name: 'RequestCreate',
                    params: {
                      requestType: GENERAL_DESCRIPTION_TRADING_MODE[EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER].ROUTE_LABEL,
                      currency: row.currency,
                      type: 'buy',
                    },
                  })
                "
              >
                Buy
              </el-button>
              <el-button
                class="ex-balance__action"
                type="success"
                :disabled="CURRENCIES_DISCONNECTED_IN_BALANCES.includes(row.currency)"
                plain
                @click="
                  autoTrading
                  ? $router.push({name: 'TradingMode', params: {currency: row.currency, type: 'sell'}})
                  : $router.push({
                    name: 'RequestCreate',
                    params: {
                      requestType: GENERAL_DESCRIPTION_TRADING_MODE[EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER].ROUTE_LABEL,
                      currency: row.currency,
                      type: 'buy',
                    },
                  })
                "
              >
                Sell
              </el-button>
              <el-dropdown trigger="click" placement="bottom-start">
                  <span class="el-dropdown-link ex-balance__menu-link">
                    <i class="material-icons">more_horiz</i>
                  </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      class="ex-balance__menu-item"
                      @click.native="$router.push({name: 'DepositCreate', params: {currency: row.currency}})"
                    >
                      Deposit
                    </el-dropdown-item>
                    <el-dropdown-item
                      class="ex-balance__menu-item"
                      @click.native="$router.push({
                      name: 'WithdrawCrypto',
                      params: {currencyType: 'crypto', currency: row.currency, type: 'manual'}
                    })"
                    >
                      Withdraw
                    </el-dropdown-item>
                    <el-dropdown-item
                      class="ex-balance__menu-item"
                      @click.native="$router.push({name: 'FundingHistory', params: {currency: row.currency}})"
                    >
                      Funding history
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div v-if="row.type === CURRENCY_TYPE.FIAT" class="ex-balance__actions">
              <el-button
                class="ex-balance__action"
                type="warning"
                plain
                @click="$router.push({name: 'DepositCreate', params: {currency: row.currency}})"
              >
                Deposit
              </el-button>
              <el-button
                class="ex-balance__action"
                type="success"
                plain
                @click="$router.push({name: 'WithdrawFiat', params: {currency: row.currency}})"
              >
<!--              TODO Temporarily hiding fiat auto withdrawal creation form-->
<!--              @click="$router.push({name: 'WithdrawalFiatChoose', params: {currency: row.currency}})"-->
                Withdraw
              </el-button>
              <el-dropdown trigger="click" placement="bottom-start">
                <span class="el-dropdown-link ex-balance__menu-link">
                  <i class="material-icons">more_horiz</i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      class="ex-balance__menu-item"
                      @click.native="$router.push({name: 'FundingHistory', params: {currency: row.currency}})"
                    >
                      Funding history
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <ex-confirm-gdpr />
    </div>
  </div>
</template>

<script>
// component
import ExReport from '@/components/ex-report';
import ExConfirmGdpr from '@/components/ex-confirm-gdpr';

// api
import TransactionApi from '@/api/transactionService/transaction.api';

// util
import {filterCoins} from '@/utils/filters';
import {formatCurrency, isNineSignCurrency} from '@/utils/converters/currency';
import {getCurrency} from '@/utils/currenciesInfo';
import {typicalPostReqSocketRes} from '@/utils/socket';

// app
import {mapActions, mapState} from 'vuex';

// const
import {GENERAL_DESCRIPTION_TRADING_MODE, EXCHANGE_PRICE_BEHAVIOUR_TYPES} from '@/constants/common';
import {
  CURRENCIES_DISCONNECTED_IN_BALANCES,
  CURRENCY_TYPE,
  SPECIAL_CURRENCIES_LIST,
} from '@/constants/currencies';
import {UPDATE_USER_ZERO_BALANCE_STATUS} from '@/constants/events/store/currentUser/actions.type';

export default {
  name: 'ExBalance',
  components: {ExConfirmGdpr, ExReport},
  data() {
    return {
      isLoading: false,
      historyBalances: null,
      currencyType: 'all',
      date: '',
      optionsCurrencies: [
        {value: 'all', label: 'All currencies'},
        {value: 'crypto', label: 'Crypto currencies'},
        {value: 'fiat', label: 'Fiat currencies'},
      ],
      SPECIAL_CURRENCIES_LIST,
      CURRENCY_TYPE,
      CURRENCIES_DISCONNECTED_IN_BALANCES,
      GENERAL_DESCRIPTION_TRADING_MODE,
      EXCHANGE_PRICE_BEHAVIOUR_TYPES,
    };
  },
  computed: {
    ...mapState('currentUser', ['profile', 'meta']),
    ...mapState('content', {autoTrading: (state) => state.content.availableSections?.autoTrading}),
    updatingZeroBalanceBehavior() {
      return this.meta.isFetching.updateZeroBalance;
    },
    currentProfileBalance() {
      const balance = this.profile?.balance || [];
      return filterCoins(balance);
    },
    prepBalance() {
      let balances = this.historyBalances !== null ? this.historyBalances : this.currentProfileBalance;
      if ([CURRENCY_TYPE.FIAT, CURRENCY_TYPE.CRYPTO].includes(this.currencyType)) {
        balances = balances.filter((item) => item.type === this.currencyType);
      }
      if (this.profile.hideZeroBalances) {
        balances = balances.filter((item) => item.amount > 0);
      }
      return balances;
    },
  },
  methods: {
    ...mapActions('currentUser', {toggleZeroBalance: UPDATE_USER_ZERO_BALANCE_STATUS}),
    getCurrency,
    formatCurrency,
    isNineSignCurrency,
    async applyTableFilter() {
      if (this.date === null) {
        this.date = ''; // TODO investigate ETH balance change
        return this.historyBalances = null;
      }
      try {
        this.isLoading = true;
        const {balance} = await typicalPostReqSocketRes({
          context: this,
          event: 'fetch:balance-history',
          request: TransactionApi.fetchBalanceHistory,
          data: {balanceId: this.profile.balanceId, date: this.date},
        });
        this.historyBalances = filterCoins(balance);
      } catch (e) {/* empty */} finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.ex-balance {
  &__menu-item {
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 22px;
  }
}
</style>
<style lang="scss" scoped>
.ex-balance {
  &__header {
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid #e5e5ea;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 15px;
  }
  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7px;
    &-select {
      max-width: 150px;
    }
  }
  &__date {
    padding: 15px 10px 0;
    margin-top: 15px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    :deep {
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        font-size: 16px;
      }
    }
  }
  &__form-item {
    flex: 1;
  }
  &__date-button {
    margin-left: 10px;
  }
  &__menu-link {
    cursor: pointer;
    i {
      display: inline-block;
      margin-top: 5px;
      color: #ff9500;
      transition: 0.3s;
    }
    &:hover {
      i {
        color: lighten(#ff9500, 10%);
        transition: 0.3s;
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
  }
  &__action {
    min-width: 95px;
    margin-right: 15px;
    --el-button-bg-color: transparent;
  }
  &__first-column-label {
    margin-left: 15px;
  }
  &__third-column-label {
    margin-left: 84px;
  }
  &__currency {
    display: flex;
    align-items: center;
  }
  &__icon-section {
    display: inline-block;
  }
  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
  }
  &__icon_blur {
    filter: blur(3px);
  }
  :deep {
    .el-table {
      border: 1px solid #EBEEF5;
    }
    .el-table .cell {
      overflow: initial;
      font-size: 16px;
    }
  }
}
</style>
