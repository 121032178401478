import merchantApi from '@/api/merchant.api';
import {
  FETCH_MERCHANT_INVOICE,
  FETCH_MERCHANT_INVOICES_LIST,
  FETCH_MERCHANT_INVOICE_REPORT,
} from '@/constants/events/store/merchantInvoice/actions.type';
import {getWSA} from '@/constants/events/actions.type';
import {hasPayloadError} from '@/utils/error';
import {errorMsg} from '@/utils/notification';

const initDate = {
  date: null,
  timezone_type: null,
  timezone: null,
};
const initStateData = {
  invoices: {
    list: [],
    pagination: {
      currentPage: 0,
      limitItems: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  currentInvoice: {
    id: null,
    createAt: initDate,
    updateAt: initDate,
    status: null,
    clientEmail: null,
    clientInvoice: null,
    currencyPair: null,
    amountCurrencyFirst: null,
    amountCurrencyLast: null,
    walletAddress: null,
    price: null,
    marginPercent: null,
    marginResult: null,
    timeout: null,
    linkId: null,
    confirmations: [],
  },
};

const state = {
  data: {...initStateData},
  meta: {
    invoicesStatus: {
      NEW: 1,
      LIVE: 2,
      CONFIRMING: 3,
      EXECUTED: 4,
      UNDERPAID: 5,
      OVERPAID: 6,
      PAIDLATE: 7,
      DROPPED: 8,
      TIMEOUT: 9,
    },
    findingInvoice: false,
    fetchingPDF: false,
  },
};

const mutations = {
  // reset() {}, // TODO
  setList: (state, payload) => {
    state.data.invoices = payload || initStateData.invoices;
  },
  clearCurrentInvoice: (state) => {
    state.data.currentInvoice = initStateData.currentInvoice;
  },
  setCurrentInvoice: (state, payload) => {
    state.data.currentInvoice = payload || initStateData.currentInvoice;
  },
  setPaginationField: (state, {field, value}) => {
    state.data.invoices.pagination[field] = value;
  },
  setFindingInvoice: (state, payload) => {
    state.meta.findingInvoice = payload;
  },
};

const actions = {
  fetchList({commit}, data) {
    // TODO check #6 Move_to_Component
    return merchantApi.fetchMerchantInvoicesList(FETCH_MERCHANT_INVOICES_LIST, data);
  },
  [getWSA(FETCH_MERCHANT_INVOICES_LIST)]: ({state, commit}, payload) => {
    // TODO check #6 Move_to_Component
    if (!hasPayloadError(payload)) {
      commit('setList', payload);
    }
  },
  fetchInvoice({commit}, data) {
    // TODO check #6 Move_to_Component
    return merchantApi.fetchMerchantInvoice(FETCH_MERCHANT_INVOICE, data);
  },
  [getWSA(FETCH_MERCHANT_INVOICE)]: ({commit}, payload) => {
    // TODO check #6 Move_to_Component
    if (!hasPayloadError(payload)) {
      commit('setCurrentInvoice', payload);
    }
  },

  fetchReport({state, getters}) {
    // TODO check #6 Move_to_Component
    if (getters.successStatus) {
      state.meta.fetchingPDF = true;

      return merchantApi.fetchMerchantInvoiceReport(
        FETCH_MERCHANT_INVOICE_REPORT,
        {invoiceId: state.data.currentInvoice.id},
      );
    }
  },
  [getWSA(FETCH_MERCHANT_INVOICE_REPORT)]: ({commit, state}, result) => {
    // TODO check #6 Move_to_Component
    if (!result.status) {
      state.meta.fetchingPDF = false;
    }

    if (!hasPayloadError(result)) {
      if (!result.status) {
        const win = window.open(result.url);
        if (win) {
          win.focus();
        } else {
          errorMsg('Please allow popups for this website');
        }
      }
    }
  },
};

const getters = {
  nameStatus: (state) => {
    return (status) => {
      const invoiceStatus = state.meta.invoicesStatus;
      switch (status) {
        case invoiceStatus.NEW:
          return 'New';
        case invoiceStatus.LIVE:
          return 'Live';
        case invoiceStatus.CONFIRMING:
          return 'Confirming';
        case invoiceStatus.EXECUTED:
          return 'Executed';
        case invoiceStatus.UNDERPAID:
          return 'Underpaid';
        case invoiceStatus.OVERPAID:
          return 'Overpaid';
        case invoiceStatus.PAIDLATE:
          return 'Paid Late';
        case invoiceStatus.DROPPED:
        case invoiceStatus.TIMEOUT:
          return 'Dropped / Timed out';
      }
    };
  },
  successStatus(state) {
    return [
      state.meta.invoicesStatus.EXECUTED,
      state.meta.invoicesStatus.OVERPAID,
    ].includes(state.data.currentInvoice.status);
  },
};

export default {namespaced: true, state, getters, mutations, actions};
