export const FETCH_ORDER_HISTORY_LIST = 'fetch:order-history';
export const FETCH_TRADE_HISTORY_LIST = 'fetch:trade-history';
export const FETCH_OPEN_ORDER_LIST = 'fetch:open-orders';
export const ACCEPT_AUTO_FIRST_CURRENCY = 'accept:auto-first-currency';
export const CANCEL_AUTO_FIRST_CURRENCY = 'cancel:auto-first-currency';
export const ACCEPT_AUTO_SECOND_CURRENCY = 'accept:auto-second-currency';
export const CANCEL_AUTO_SECOND_CURRENCY = 'cancel:auto-second-currency';
export const INIT_SUBSCRIBE_SPAM_PRICE_AUTO_TRADING = 'request:init-subscribe-spam-price';
export const FETCH_FIAT_AUTO_WITHDRAWAL_LIST = 'request:fetch-fiat-auto-withdrawal';
export const FETCH_REQUEST_LIST_IN_PROGRESS = 'request:fetch-withdrawal-list-in-progress';
export const FETCH_REQUEST = 'request:fetch';
export const FETCH_WITHDRAW_CRYPTO_AUTO_LIST_CURRENCIES = 'fetch:withdraw-crypto-auto-list-currencies';
export const FETCH_WITHDRAW_CRYPTO_MANUAL_LIST_CURRENCIES = 'fetch:withdraw-crypto-manual-list-currencies';
export const FETCH_WITHDRAW_FIAT_MANUAL_LIST_CURRENCIES = 'fetch:withdraw-fiat-manual-list-currencies';
export const CREATE_WITHDRAW_CRYPTO_MANUAL = 'create:withdraw-crypto-manual';
export const CREATE_WITHDRAW_FIAT_MANUAL = 'create:withdraw-fiat-manual';
