// guard
import {accIsNotVerified, authBehavior, additionalRole} from '@/routes/authRouters';

// component
const ExGateway = () => import('@/pages/gateway/ex-gateway');
import ExBalance from '@/pages/ex-balance.vue';

// const
import {ROLE_BILLING, ROLE_INTERNAL_TRANSFER, ROLE_MERCHANT} from '@/constants/roles';

export default (store) => {
  return [
    {
      path: '/on-boarding',
      component: () => import('@/pages/onBoarding/ex-on-boarding'),
      name: 'OnBoarding',
      beforeEnter: accIsNotVerified,
      meta: {store},
    },
    {
      path: '/dashboard',
      redirect: {name: 'BalancesIndex'},
      beforeEnter: authBehavior,
      meta: {store},
      children: [
        {path: 'balances', component: ExBalance, name: 'BalancesIndex'},
        {
          path: 'profile',
          component: () => import('@/pages/setting/ex-setting-tabs'),
          name: 'route-user-profile',
        },
        {
          path: 'orders/:type',
          component: () => import('@/pages/orders/ex-order-tabs'),
          name: 'Orders',
        },
        {
          path: 'order/type/:type/:currency',
          component: () => import('@/pages/requests/ex-trading-mode'),
          name: 'TradingMode',
        },
        {
          path: 'order/create/:requestType/:type/:currency',
          component: () => import('@/pages/requests/ex-create-request.vue'),
          name: 'RequestCreate',
        },
        {
          path: 'order/deposit/:type/:currency',
          component: () => import('@/pages/deposit/fiat/ex-deposit-manual-fiat'),
          name: 'FiatDeposit',
        },
        {
          path: 'order/deposit/:currency',
          component: () => import('@/pages/deposit/ex-deposit-currency-type-selector'),
          name: 'DepositCreate',
        },
        {
          path: 'order/:id',
          component: () => import('@/pages/requests/ex-trading-container'),
          name: 'Trading',
        },
        {
          path: 'withdrawals',
          component: () => import('@/pages/withdrawal/ex-withdrawal-list'),
          name: 'WithdrawalList',
        },
        {
          path: 'order/withdrawal',
          children: [
            {
              path: 'fiat',
              children: [
                // TODO Temporarily hiding fiat auto withdrawal creation form
                // {
                //   path: 'choose/:currency',
                //   component: () => import('@/pages/withdrawal/fiat/ex-withdrawal-fiat-choose'),
                //   name: 'WithdrawalFiatChoose',
                // },
                {
                  path: ':currency',
                  component: () => import('@/pages/withdrawal/fiat/ex-withdrawal-fiat'),
                  name: 'WithdrawFiat',
                },
              ],
            },
            {
              path: 'crypto/:type/:currency',
              component: () => import('@/pages/withdrawal/crypto/ex-withdrawal-crypto-tabs'),
              name: 'WithdrawCrypto',
            },
          ],
        },
        {
          path: 'transactions',
          component: () => import('@/pages/transactions/ex-transaction'),
          name: 'Transaction',
        },
        {
          path: 'funding/:currency',
          component: () => import('@/components/ex-funding-history'),
          name: 'FundingHistory',
        },
        {
          path: 'invoices',
          beforeEnter: additionalRole,
          meta: {store, role: ROLE_MERCHANT},
          children: [
            {
              path: '',
              component: () => import('@/pages/merchant/merchantInvoices'),
              name: 'MerchantInvoices',
            },
            {
              path: 'create',
              component: () => import('@/pages/merchant/ex-invoice-create'),
              name: 'MerchantInvoiceCreate',
            },
            {
              path: 'view/:id',
              component: () => import('@/pages/merchant/merchantInvoiceView'),
              name: 'MerchantInvoiceView',
            },
          ],
        },
        {
          path: 'gateway',
          redirect: {name: 'GatewayIncoming'},
          name: 'Gateway',
          beforeEnter: additionalRole,
          meta: {store, role: ROLE_BILLING},
          children: [
            {path: 'incoming', component: ExGateway, name: 'GatewayIncoming'},
            {path: 'outgoing', component: ExGateway, name: 'GatewayOutgoing'},
          ],
        },
        {
          path: 'internal-transfer/create',
          component: () => import('@/pages/internalTransfer/ex-internal-transfer-create'),
          name: 'InternalTransferCreate',
          beforeEnter: additionalRole,
          meta: {store, role: ROLE_INTERNAL_TRANSFER},
        },
      ],
    },
  ];
};
