import {isObject, cloneDeep} from 'lodash';
/**
 * @param {Object} source
 * @param {Object} receiver
 */
export function resetState(source, receiver) {
  Object.keys(source).forEach((key) => {
    receiver[key] = isObject(source[key]) ? cloneDeep(source[key]) : source[key];
  });
}
