<template>
  <div class="ex-authorization-layout">
    <div v-if="$slots.subContent" class="ex-authorization-layout__sub-content">
      <slot name="subContent" />
    </div>
    <div v-if="$slots.title" class="ex-authorization-layout__title">
      <slot name="title" />
    </div>
    <div v-if="$slots.subTitle" class="ex-authorization-layout__sub-title">
      <slot name="subTitle" />
    </div>
    <div v-if="$slots.default" class="ex-authorization-layout__content">
      <slot />
    </div>
    <div v-if="$slots.footer" class="ex-authorization-layout__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExAuthorizationLayout',
};
</script>

<style lang="scss" scoped>
.ex-authorization-layout {
  font-family: "Roboto", sans-serif;
  &__sub-content {
    padding: 20px 0 32px;
  }
  &__title {
    font-size: 48px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 600;
    line-height: 52px;
    padding-bottom: 10px;
  }
  &__sub-title {
    @include mix--typo-body0;
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 40px;
    color: $--color-auth-typo-caption;
  }
  &__content {
    padding-bottom: 20px;
  }
  &__footer {
    @include mix--typo-body1;
    font-weight: 400;
    color: $--color-auth-typo-caption;
    text-align: center;
  }
  :deep {
    .el-input__wrapper {
      height: 64px;
      border-radius: $--radius-st;
      transition: border-colos 0.3s;
      @include mix--typo-body1;
    }
    .el-select__wrapper {
      height: 64px;
      border-radius: $--radius-st;
      transition: border-colos 0.3s;
      @include mix--typo-body1;
    }
    input:focus {
      border-color: $--color-auth-primary-l1;
      transition: border-colos 0.3s;
    }
    .el-checkbox {
      display: flex;
      margin-bottom: 15px;
      .el-checkbox__input {
        .el-checkbox__inner {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          border: 2px solid #CED4DA;
        }
      }
      .el-checkbox__label {
        padding-top: 3px;
        white-space: normal;
        padding-left: 14px;
        font-size: 16px;
        color: $--color-auth-typo-regular-l2;
        letter-spacing: 0.1px;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: $--color-auth-primary;
      }
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: $--color-auth-typo-regular-l2;
      }
      .el-checkbox__inner {
        margin-top: 0;
        height: 24px;
        width: 24px;
        &:after {
          border: 3px solid #ffffff;
          border-left: 0;
          border-top: 0;
          height: 10px;
          left: 7px;
          top: 2px;
        }
      }
    }
    .el-radio-button__inner {
      color: $--color-auth-typo-link2;
      background: #e1e9f4;
      border-color: #b5cffa;
      font-weight: bold;
      padding: 14px 26px;
    }
    .el-radio-button:first-child .el-radio-button__inner {
      border-radius: $--radius-st 0 0 $--radius-st;
    }
    .el-radio-button:last-child .el-radio-button__inner {
      border-radius: 0 $--radius-st $--radius-st 0;
    }
    .el-radio-button__original-radio:checked + .el-radio-button__inner {
      background: #e0aeff;
      border-color: #e0aeff;
      box-shadow: inset 0 0 12px 3px rgba(172, 83, 227, 0.45) !important;
      color: #562b70;
    }
    .el-form-item__label {
      padding: 0;
      line-height: 19px;
      margin-bottom: 0.25rem;
      color: $--color-auth-typo-regular;
    }
    .el-input__inner {
      border-radius: $--radius-st;
      padding: 0 20px;
      font-size: 18px;
    }
    .el-form-item {
      margin-bottom: 25px;
    }
    .el-input .el-input__clear {
      margin-right: 12px;
      font-size: 20px;
    }
    .el-input .el-input__suffix {
      top: 1px;
    }
    .ex-button__button {
      border-radius: $--radius-st;
      font-size: 18px;
      height: 60px;
      transition: background 0.3s;
    }
    .el-radio-button__inner {
      color: #889cbd;
    }
    .el-form-item__error {
      padding-left: 10px;
    }
  }
  @include mix--media('xs') {
    &__title {
      font-size: 24px;
      line-height: 29px;
      padding-bottom: 8px;
    }
    &__sub-title {
      @include mix--typo-body1;
      font-weight: 400;
      padding-bottom: 32px;
    }
    :deep {
      .el-input__wrapper {
        height: 49px;
      }
      .vue-tel-input {
        height: 49px;
      }
    }
  }
}
</style>
