export const CURRENCY_TYPE = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
};

/**
 * Check when adding a new coin
 */
export const CRYPTO_CURRENCIES = {
  'BCH': 'BCH', // deprecated
  'ETC': 'ETC', // deprecated
  'LTC': 'LTC', // deprecated
  'XRP': 'XRP', // deprecated
  'C+': 'C+', // deprecated
  'USDT_OMNI': 'USDT_OMNI', // deprecated
  'BUSD': 'BUSD', // deprecated
  'TUSD': 'TUSD', // deprecated
  'GUSD': 'GUSD', // deprecated
  'BTC': 'BTC',
  'ETH': 'ETH',
  'USDT_ERC20': 'USDT_ERC20',
  'USDT_TRC20': 'USDT_TRC20',
  'USDT_Polygon': 'USDT_Polygon',
  'USDT_BEP20': 'USDT_BEP20',
  'USDT_SOL': 'USDT_SOL',
  'USDC': 'USDC', // USDC_ERC20
  'USDC_ERC20': 'USDC',
  'USDC_Polygon': 'USDC_Polygon',
  'USDC_BEP20': 'USDC_BEP20',
  'USDC_SOL': 'USDC_SOL',
  'DAI': 'DAI',
  'ETH_BEP20': 'ETH_BEP20',
  'TRX': 'TRX',
  'MATIC_Polygon': 'MATIC_Polygon',
  'BNB': 'BNB',
  'TON': 'TON',
  'SOL': 'SOL',
};

export const CRYPTO_CURRENCIES_LIST = Object.values(CRYPTO_CURRENCIES);

/**
 * Check when adding a new coin
 */
export const FIAT_CURRENCIES = {
  EUR: 'EUR',
  USD: 'USD',
  RUB: 'RUB',
};
/**
 * Check when adding a new coin
 */
export const FIAT_CURRENCIES_LIST = Object.values(FIAT_CURRENCIES);

/**
 * Check when adding a new coin
 */
export const TWO_SIGNS_CURRENCIES = [...FIAT_CURRENCIES_LIST];

export const TEMPORARILY_UNAVAILABLE_COINS = [
  CRYPTO_CURRENCIES.BCH,
  CRYPTO_CURRENCIES.ETC,
  CRYPTO_CURRENCIES.LTC,
  CRYPTO_CURRENCIES.XRP,
  CRYPTO_CURRENCIES.USDT_OMNI,
  CRYPTO_CURRENCIES['C+'],
  CRYPTO_CURRENCIES.TUSD,
  CRYPTO_CURRENCIES.GUSD,
  CRYPTO_CURRENCIES.BUSD,
  FIAT_CURRENCIES.RUB,
];

export const AVAILABLE_CRYPTO_CURRENCY_LIST =
  CRYPTO_CURRENCIES_LIST.filter((item) => !TEMPORARILY_UNAVAILABLE_COINS.includes(item));

export const AVAILABLE_FIAT_CURRENCY_LIST =
  FIAT_CURRENCIES_LIST.filter((item) => !TEMPORARILY_UNAVAILABLE_COINS.includes(item));


/**
 * Check when adding a new coin
 */
export const SIX_SIGNS_CURRENCIES_LIST = [
  CRYPTO_CURRENCIES.USDT_ERC20,
  CRYPTO_CURRENCIES.USDT_TRC20,
  CRYPTO_CURRENCIES.USDT_BEP20,
  CRYPTO_CURRENCIES.USDT_Polygon,
  CRYPTO_CURRENCIES.USDT_SOL,
  CRYPTO_CURRENCIES.USDC,
  CRYPTO_CURRENCIES.USDC_SOL,
  CRYPTO_CURRENCIES.BUSD,
  CRYPTO_CURRENCIES.DAI,
  CRYPTO_CURRENCIES.TRX,
  CRYPTO_CURRENCIES.USDC_Polygon,
];

/**
 * Check when adding a new coin
 */
export const NINE_SIGNS_CURRENCIES_LIST = [
  CRYPTO_CURRENCIES.TON,
  CRYPTO_CURRENCIES.SOL,
];

/**
 * Check when adding a new coin
 */
export const WALLETS_LIKE_ETH_LIST = [
  CRYPTO_CURRENCIES.USDT_ERC20,
  CRYPTO_CURRENCIES.USDT_BEP20,
  CRYPTO_CURRENCIES.USDC,
  CRYPTO_CURRENCIES.USDC_ERC20,
  CRYPTO_CURRENCIES.BUSD,
  CRYPTO_CURRENCIES.DAI,
  CRYPTO_CURRENCIES.BNB,
  CRYPTO_CURRENCIES.ETH_BEP20,
  CRYPTO_CURRENCIES.USDC_BEP20,
  CRYPTO_CURRENCIES.USDT_Polygon,
  CRYPTO_CURRENCIES.MATIC_Polygon,
  CRYPTO_CURRENCIES.USDC_Polygon,
];

/**
 * Check when adding a new coin
 */
export const WALLETS_LIKE_TRX_LIST = [
  CRYPTO_CURRENCIES.USDT_TRC20,
];

/**
 * Check when adding a new coin
 */
export const WALLETS_LIKE_SOL_LIST = [
  CRYPTO_CURRENCIES.USDT_SOL,
  CRYPTO_CURRENCIES.USDC_SOL,
];

export const SPECIAL_CURRENCIES_LIST = [
  // CRYPTO_CURRENCIES.USDT_Polygon, // example
];
export const SPECIAL_CURRENCIES = SPECIAL_CURRENCIES_LIST;

export const CURRENCIES_DISCONNECTED_IN_BALANCES = [
  ...SPECIAL_CURRENCIES_LIST,
  CRYPTO_CURRENCIES.BNB,
];
