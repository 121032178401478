import {createRouter, createWebHistory} from 'vue-router';
import publicRoutes from '@/routes/public';
import privateRoutes from '@/routes/private';

const routes = (store) => [
  {path: '/', name: 'Home', redirect: {name: 'BalancesIndex'}},
  {path: '/error/:error', component: () => import('@/pages/error/ex-error'), name: 'ErrorPage', props: true},
].concat(publicRoutes(store), privateRoutes(store));

const router = (store) => createRouter({
  history: createWebHistory(),
  routes: routes(store),
});

export default router;
