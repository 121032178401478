import BaseApi from '@/api/base';

export default new class Content extends BaseApi {
  /**
   * Get the current version and text of the GDPR policy
   * @return {Promise<*>}
   */
  fetch() {
    return super.post('', '/api/content/fetch-gdpr');
  }

  /**
   * @return {Promise<*>}
   */
  fetchBasicContent() {
    return super.post('', '/api/content/fetch-basic-content');
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchBasicCurrencies(event) {
    return super.post(event, '/api/content/fetch-basic-currencies');
  }
};
