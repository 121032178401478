<template>
  <ex-dialog
    :model-value="true"
    title="Thank you! Your account has been confirmed!"
    width="559px"
    class="ex-registration-final"
  >
    You can already sign in, however, the service functionality is limited prior your full verification.
    Please, sign in and complete all steps for KYC purposes.
    In case you are not able to proceed with further steps, please make sure to contact
    <a :href="`mailto:${email}`">{{email}}</a>
    <template #footer>
      <ex-button class="ex-registration-final__button" @click="$router.push({name: 'LoginCredential'})">
        Sign in
      </ex-button>
    </template>
  </ex-dialog>
</template>

<script>
import ExButton from '@/components/authorization/ui/ex-button';
import ExDialog from '@/components/authorization/ui/ex-dialog';
import {mapState} from 'vuex';
export default {
  name: 'ExRegistrationFinal',
  components: {ExButton, ExDialog},
  computed: {...mapState('content', {email: (state) => state.content.supportEmail})},
};
</script>

<style lang="scss" scoped>
.ex-registration-final {
  &__button {
    width: 100%;
  }
}
</style>
