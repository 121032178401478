import ErrorMessage from '@/utils/socketError/errorNotifier';
import {errorMsg} from '@/utils/notification';

/***/
export default class StoreErrorNotifier {
  static _storeEvents = StoreErrorNotifier._prepStoreEvents();

  /**
   * @return {string[]}
   */
  static _prepStoreEvents() {
    const listStaticExcludedEvents = require.context('@/constants/events/store', true, /actions.type.js$/);
    let eventStorage = [];
    listStaticExcludedEvents.keys().forEach((file) => {
      const importedEventList = listStaticExcludedEvents(file);
      eventStorage = [...eventStorage, ...Object.values(importedEventList)];
    });
    return eventStorage;
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {Object} data.error
   */
  static handleError(event, data) {
    if (StoreErrorNotifier._storeEvents.includes(event)) errorMsg(ErrorMessage.getMessage(data, event));
  }
}
