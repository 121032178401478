import {CREATE_OUTGOING} from '@/constants/events/tunnel/actions.type';
import {
  RECOVERY_COMPLETE,
  RECOVERY_VALIDATE_EMAIL_CODE,
  RECOVERY_VALIDATE_GA_CODE,
  RECOVERY_VALIDATE_SMS_CODE,
} from '@/constants/events/recovery/actions.type';
import {
  INIT_EMAIL_VERIFICATION,
  INIT_PHONE_NUMBER_VERIFICATION,
  VERIFY_EMAIL,
  VERIFY_PHONE_NUMBER,
} from '@/constants/events/registration/actions.type';
import {
  REQUEST_SMS_CODE_LOGIN,
  RE_REQUEST_CODE_LOGIN,
  REQUEST_GA_CODE_LOGIN,
  VERIFY_SMS_CODE_LOGIN,
  VERIFY_GA_CODE_LOGIN,
  INIT_LOGIN,
} from '@/constants/events/login/actions.type';

const EXCLUDED_ALL = 'all';

// DECRYPTION
// FETCH_USER - socket event,
// 2 - error source,
// 237 - error code
// or
// EXCLUDED_ALL - Excludes all codes for this event

// Events excluded from centralized processing or processed locally
//
// Used for the old socket event handler (socketRequest)
//
export default {
  // [FETCH_USER]: {2: [237]}, // example
  [RECOVERY_COMPLETE]: EXCLUDED_ALL,
  [RECOVERY_VALIDATE_SMS_CODE]: EXCLUDED_ALL,
  [RECOVERY_VALIDATE_EMAIL_CODE]: EXCLUDED_ALL,
  [RECOVERY_VALIDATE_GA_CODE]: EXCLUDED_ALL,
  [CREATE_OUTGOING]: {33: [33]},
  [INIT_PHONE_NUMBER_VERIFICATION]: EXCLUDED_ALL,
  [VERIFY_PHONE_NUMBER]: EXCLUDED_ALL,
  [INIT_EMAIL_VERIFICATION]: EXCLUDED_ALL,
  [VERIFY_EMAIL]: EXCLUDED_ALL,
  [INIT_LOGIN]: EXCLUDED_ALL,
  [REQUEST_SMS_CODE_LOGIN]: EXCLUDED_ALL,
  [VERIFY_SMS_CODE_LOGIN]: EXCLUDED_ALL,
  [RE_REQUEST_CODE_LOGIN]: EXCLUDED_ALL,
  [REQUEST_GA_CODE_LOGIN]: EXCLUDED_ALL,
  [VERIFY_GA_CODE_LOGIN]: EXCLUDED_ALL,
  // []: {: []},
};
