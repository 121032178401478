import {
  SIX_SIGNS_CURRENCIES_LIST,
  CURRENCY_TYPE,
  NINE_SIGNS_CURRENCIES_LIST,
  CRYPTO_CURRENCIES_LIST,
  FIAT_CURRENCIES_LIST,
  TWO_SIGNS_CURRENCIES,
  // AVAILABLE_CRYPTO_CURRENCY_LIST, // TON
} from '@/constants/currencies';
import {isString} from 'lodash';
import {getCurrency} from '@/utils/currenciesInfo';

/**
 * @param {number|string} number
 * @param {string} format
 * @param {{
 * maximumFractionDigits?: number,
 * minimumFractionDigits?: number,
 * }} formatOptions
 * @return {string}
 */
function numberToLocaleString(number, format = 'en', formatOptions = {}) {
  return Number(number).toLocaleString(format, formatOptions);
}

/**
 *
 * @param {string} value
 * @param {string} format
 * @return {float|number}
 */
export function numberFromLocaleString(value, format = 'en') {
  switch (format) {
    case 'en':
      if (value !== null) {
        if (typeof value === 'string') {
          return +value.replaceAll(',', '');
        }
        return +value;
      }
      return 0;
    default:
      return 0;
  }
}

/**
 *
 * @param {string} currencyType
 * @return {number}
 */
function getDefaultCurrencyPrecision(currencyType) {
  switch (currencyType) {
    case CURRENCY_TYPE.FIAT:
      return 2;
    case CURRENCY_TYPE.CRYPTO:
      return 8;
    default:
      return 8;
  }
}

/**
 * @param {number|string} amount
 * @param {string} [currencyType]
 * @param {number} [precision]
 * @param {string} currency
 * @return {*}
 */
export function formatCurrency(amount, currencyType = '', precision = null, currency = null) {
  if (isNaN(parseFloat(amount)) && !isFinite(amount)) return amount;
  let precisionValue = precision;
  if (precision === null) {
    // if (AVAILABLE_CRYPTO_CURRENCY_LIST.includes(currency)) { // TON
    //   precisionValue = getPrecisionByCurrency(currency);
    // }
    // else precisionValue = getDefaultCurrencyPrecision(currencyType);
    precisionValue = getDefaultCurrencyPrecision(currencyType);
  }
  return numberToLocaleString(
    numberFromLocaleString(amount),
    'en',
    {
      maximumFractionDigits: precisionValue,
      minimumFractionDigits: precisionValue,
    },
  );
}

/**
 * @param {number|string} amount
 * @param {string} currency
 * @param {number} precision
 * @return {string}
 */
export function getAmountInCurrencyFormat(amount, currency, precision = null) {
  let precisionValue = precision;
  if (precision === null) {
    if (isFiat(currency)) {
      precisionValue = getDefaultCurrencyPrecision(CURRENCY_TYPE.FIAT);
    } else if (isSixSignsCurrency(currency)) {
      precisionValue = 6;
    // } else if (isNineSignCurrency(currency)) { // TON
    //   precisionValue = 9;
    } else if (isCrypto(currency)) {
      precisionValue = getDefaultCurrencyPrecision(CURRENCY_TYPE.CRYPTO);
    } else {
      precisionValue = getDefaultCurrencyPrecision(CURRENCY_TYPE.CRYPTO);
    }
  }
  return numberToLocaleString(
    numberFromLocaleString(amount),
    'en',
    {
      maximumFractionDigits: precisionValue,
      minimumFractionDigits: precisionValue,
    },
  );
}

/**
 * @param {string} pair
 * @param {string} separator
 * @return {{'0', seller, '1', first, buyer, second}|{}}
 */
export function getPairCurrencies(pair, separator = ':') {
  if (pair !== undefined && isString(pair) && pair.split(separator).length > 0) {
    const currencies = pair.split(separator);

    return {
      0: currencies[0],
      1: currencies[1],
      seller: currencies[0],
      buyer: currencies[1],
      first: currencies[0],
      second: currencies[1],
    };
  }

  return {};
}

/**
 * Check currency as crypto
 * @param {string} currency
 * @return {boolean}
 */
export function isCrypto(currency) {
  return CRYPTO_CURRENCIES_LIST.includes(currency);
}

/**
 * Check currency as fiat
 * @param {string} currency
 * @return {boolean}
 */
export function isFiat(currency) {
  return FIAT_CURRENCIES_LIST.includes(currency);
}

/**
 * @param {string} currency
 * @return {boolean}
 */
export function isTwoSignsCurrency(currency) {
  return TWO_SIGNS_CURRENCIES.includes(currency);
}

/**
 * @param {string} currency
 * @return {boolean}
 */
export function isSixSignsCurrency(currency) {
  return SIX_SIGNS_CURRENCIES_LIST.includes(currency);
}

/**
 * @param {string} currency
 * @return {boolean}
 */
export function isNineSignCurrency(currency) {
  return NINE_SIGNS_CURRENCIES_LIST.includes(currency);
}

/**
 * @param {string} currency
 * @return {boolean}
 */
export function isEightSignCurrency(currency) {
  return !isTwoSignsCurrency(currency)
    && !isNineSignCurrency(currency)
    && !isSixSignsCurrency(currency);
}

/**
 * @param {string} currency
 * @return {number}
 */
export function getPrecisionByCurrency(currency = 'default eight precision') {
  return isTwoSignsCurrency(currency) ? 2 : isSixSignsCurrency(currency) ? 6 : isNineSignCurrency(currency) ? 9 : 8;
}

/**
 * @param {number} value
 * @param {string} currency
 * @return {number}
 */
export function roundPrecisionByCurrency(value, currency= 'default eight precision') {
  return +(value.toFixed(getPrecisionByCurrency(currency)));
}

/**
 * @param {number} value
 * @param {string} currency
 * @return {number}
 */
export function cutPrecisionByCurrency(value, currency= 'default eight precision') {
  const precision = getPrecisionByCurrency(currency);
  const regExp = new RegExp('(\\d+\\.\\d{' + precision + '})(\\d)');
  const result = String(value).match(regExp);
  return result ? parseFloat(result[1]) : +value;
}

/**
 * @param {string} pair
 * @param {string} elseLabel
 * @return {string}
 */
export function getPairLabel(pair, elseLabel = 'N/A') {
  if (pair.indexOf(':') === -1) return elseLabel;
  const {first, second} = getPairCurrencies(pair);
  return `${getCurrency(first).name} / ${getCurrency(second).name}`;
}
