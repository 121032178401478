<template>
  <div v-if="serverTimeLocaleString" class="ex-clock">
    {{serverTimeLocaleString}}
  </div>
</template>

<script>
// api
import {getServerDateTime} from '@/api/common.api';

// util
import moment from 'moment/moment';
import getWorkerNonSleepTimer from '@/utils/nonSleepTimer';

export default {
  name: 'ExClock',
  data() {
    return {
      serverDateTime: null,
      serverTimeLocaleString: '',
      worker: null,
      numberOfTicksSincePreviousRequest: 60, // init value for first execution
      firstFetch: true,
    };
  },
  created() {
    this.fetchServerTime();
  },
  unmounted() {
    if (this.worker !== null) this.worker.terminate();
  },
  methods: {
    startTick() {
      this.worker = getWorkerNonSleepTimer();
      this.worker.onmessage = () => {
        this.fetchServerTime();
        this.startClock();
      };
      this.worker.postMessage(null);
    },
    async fetchServerTime() {
      if (this.numberOfTicksSincePreviousRequest < 60) {
        return this.numberOfTicksSincePreviousRequest++;
      } else {
        this.numberOfTicksSincePreviousRequest = 0;
      }
      const {dateTime, offset} = await getServerDateTime();
      const clientDate = new Date();
      const clientOffset = clientDate.getTimezoneOffset();
      const updatedOffset = clientOffset - offset;
      this.serverDateTime = dateTime + (updatedOffset * 60 * 1000);
      if (this.firstFetch) {
        this.startTick();
        this.startClock(0);
        this.firstFetch = false;
      }
    },
    startClock(increment = 1000) {
      if (!this.serverDateTime) return;
      this.serverDateTime = this.serverDateTime + increment;
      this.serverTimeLocaleString = moment(this.serverDateTime).format('MMMM Do YYYY, h:mm:ss A');
    },
  },
};
</script>
