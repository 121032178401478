<template>
  <div class="ex-registration-base">
    <ex-authorization>
      <template #title>Create an account</template>
      <template #subTitle>Sign up to {{brand}} Digital for free!</template>
      <ex-authorization-form
        v-loading="isCreating || isLoading"
        @mClick="createNewUser"
        :message="{value: errMessage, type: 'danger'}"
      >
        <div class="ex-registration-base__choose">
          <el-radio-group v-model="form.userType" :disabled="displayMode">
            <el-radio-button :label="USER_TYPE.PERSON">
              <el-icon class="el-icon--left"><User/></el-icon>
              Person
            </el-radio-button>
            <el-radio-button :label="USER_TYPE.COMPANY">
              <el-icon class="el-icon--left"><OfficeBuilding/></el-icon>
              Company
            </el-radio-button>
          </el-radio-group>
        </div>
        <el-form :model="form" label-position="top" :rules="rules" ref="form">
          <el-form-item label="Email address" prop="email">
            <el-input
              ref="email"
              autofocus
              autocomplete="nope"
              placeholder="Type your email"
              v-model.trim="form.email"
              :disabled="displayMode"
            />
          </el-form-item>
          <el-form-item v-if="!displayMode" label="Create password" prop="password">
            <el-input
              ref="password"
              autocomplete="nope"
              placeholder="Type your password"
              type="password"
              v-model="form.password"
              show-password
            />
          </el-form-item>
          <el-form-item prop="phone" label="Phone number">
            <ex-tel-input
              v-model="form.phone"
              :inputOptions="{placeholder: 'Type your phone number'}"
              :enabledCountryCode="false"
              :disabled="displayMode"
              @validate="validatePhone"
              @countryChanged="countryChanged"
              mode="international"
            />
          </el-form-item>
          <template v-if="form.userType === USER_TYPE.COMPANY">
            <el-form-item label="Company name" prop="organizationName">
              <el-input
                autocomplete="nope"
                placeholder="Type your company name"
                v-model="form.organizationName"
                :disabled="displayMode"
              />
            </el-form-item>
            <el-form-item label="Registration number" prop="organizationNumber" withBorder>
              <el-input
                autocomplete="nope"
                placeholder="Type the registration number"
                v-model="form.organizationNumber"
                :disabled="displayMode"
              />
            </el-form-item>
          </template>
          <el-form-item v-if="!displayMode" label="Invite code" prop="registrationCode" withBorder>
            <el-input
              autocomplete="nope"
              :disabled="disableInviteCode"
              placeholder="Type your invite code"
              v-model="form.registrationCode"
            />
          </el-form-item>
          <el-form-item v-if="!displayMode" prop="agreements">
            <el-checkbox-group v-model="form.agreements">
              <el-checkbox label="1" class="ex-registration-base__checkbox">
                I agree to
                <ex-link color="primary" :href="`${fileStorageUrl}/cdn/policy/tos`" target="_blank">
                  Terms of Service
                </ex-link>
              </el-checkbox>
              <el-checkbox label="2" class="ex-registration-base__checkbox">
                I agree to
                <ex-link color="primary" :href="`${fileStorageUrl}/cdn/policy/privacy`" target="_blank">
                  GDPR Policy
                </ex-link>
              </el-checkbox>
              <el-checkbox label="3" class="ex-registration-base__checkbox">
                <div class="ex-registration-base__checkbox-text">
                  I certify that I am not a U.S. person according to
                  <ex-link
                    color="primary"
                    href="https://www.irs.gov/individuals/international-taxpayers/classification-of-taxpayers-for-us-tax-purposes"
                    target="_blank"
                  >
                    IRS classification
                  </ex-link>
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <slot />
        <el-button
          v-if="displayMode"
          @click="$emit('changeBaseData')"
          class="ex-registration-base__button"
          type="text"
        >
          Change data
        </el-button>
        <template v-if="displayMode" #action><span/></template>
        <template #actionLabel>Create account</template>
      </ex-authorization-form>
    </ex-authorization>
  </div>
</template>

<script>
// api
import RegistrationApi from '@/api/userService/registration.api';

// const
import {USER_TYPE} from '@/constants/common';

// util
import {ruleMaxLength} from '@/utils/elementPlusTypicalValidation';
import {registration} from '@/utils/web-storage/cookie';
import {typicalPostReqSocketRes} from '@/utils/socket';
import {RegistrationSessionStorage} from '@/utils/web-storage/session';
import {FILE_STORAGE_URL} from '@/utils/appsURLs';

// component
import ExTelInput from '@/components/authorization/ex-tel-input';
import ExAuthorization from '@/components/authorization/layout/ex-authorization-layout';
import ExAuthorizationForm from '@/components/authorization/layout/ex-authorization-form';
import ExLink from '@/components/authorization/ui/ex-link';
import {User, OfficeBuilding} from '@element-plus/icons-vue';
import {mapState} from 'vuex';

// setting
const SPECIAL_COUNTRIES_FOR_TWILIO = [
  'CU', // Cuba
  'EG', // Egypt
  'ID', // Indonesia
  'NG', // Nigeria
  'OM', // Oman
  'PK', // Pakistan
  'PS', // Palestine
  'SA', // Saudi Arabia
  'TZ', // Tanzania
  'VN', // Vietnam
  'JP', // Japan
];

export default {
  name: 'ExRegistrationBase',
  components: {
    ExTelInput,
    ExLink,
    ExAuthorizationForm,
    ExAuthorization,
    OfficeBuilding,
    User,
  },
  props: {
    displayMode: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (SPECIAL_COUNTRIES_FOR_TWILIO.includes(this.phone.country)) {
        callback(new Error('Sorry, we don’t support SMS verification for numbers from this country. Please use another phone number'));
      }
      if (!this.phone.valid) callback(new Error('Please, enter a valid phone number'));
      else callback();
    };
    const validateAgreements = (rule, value, callback) => {
      if (value.length !== 3) callback(new Error('Please, agree to all terms of use'));
      callback();
    };
    const requiredRule = (fieldName) => ({required: true, trigger: 'blur', message: `Please, enter a ${fieldName}`});

    return {
      phone: {
        country: null,
        valid: null,
      },
      form: {
        email: '',
        phone: '',
        password: '',
        userType: USER_TYPE.COMPANY,
        registrationCode: '',
        organizationName: '',
        organizationNumber: '',
        agreements: [],
      },
      rules: {
        email: [
          requiredRule('email address'),
          {type: 'email', trigger: ['blur', 'change'], message: 'Please, enter a valid email address'},
        ],
        password: [
          ruleMaxLength(),
          requiredRule('password'),
        ],
        phone: [
          {validator: validatePhoneNumber, trigger: 'change'},
          requiredRule('phone number'),
        ],
        organizationName: [
          ruleMaxLength(),
          requiredRule('company name'),
        ],
        organizationNumber: [
          ruleMaxLength(),
          requiredRule('registration number'),
        ],
        registrationCode: [
          ruleMaxLength(),
          requiredRule('invite code'),
        ],
        agreements: [{validator: validateAgreements, trigger: ['blur', 'change']}],
      },
      isCreating: false,
      USER_TYPE,
      disableInviteCode: false,
      errMessage: '',
    };
  },
  computed: {
    ...mapState('content', {brand: (state) => state.content.brand}),
    fileStorageUrl() {
      return `${FILE_STORAGE_URL}`;
    },
  },
  created() {
    Object.assign(this.form, RegistrationSessionStorage.user);

    const inviteCode = this.$route.query.invite;
    if (inviteCode) {
      registration.setCookieInviteCode(inviteCode);
      this.form.registrationCode = inviteCode;
      this.disableInviteCode = true;
    } else {
      const inviteCode = registration.getCookieInviteCode();
      if (inviteCode) {
        this.form.registrationCode = inviteCode;
        this.disableInviteCode = true;
      }
    }
  },
  methods: {
    async initValidatePhoneNumber() {
      try {
        await this.$refs.form.validateField('phone');
      } catch (error) {/**/}
    },
    countryChanged({iso2}) {
      this.phone.country = iso2;
      if (iso2 === undefined) return this.$refs.form.clearValidate('phone');
      this.initValidatePhoneNumber();
    },
    validatePhone({valid}) {
      const currentValidationStatus = this.phone.valid;
      this.phone.valid = !!valid;
      if (SPECIAL_COUNTRIES_FOR_TWILIO.includes(this.phone.country)) return this.initValidatePhoneNumber();
      if (currentValidationStatus === null && !this.phone.valid) return this.$refs.form.clearValidate('phone');
      this.initValidatePhoneNumber();
    },
    async createNewUser() {
      try {
        this.errMessage = '';
        await this.$refs.form.validate();
        this.isCreating = true;
        await typicalPostReqSocketRes({
          context: this,
          event: 'create:user',
          request: RegistrationApi.initRegistration,
          data: this.prepUserData(),
          localErrorHandling: true,
        });
        this.form.password = '';
        this.form.agreements = [];
        RegistrationSessionStorage.user = this.form;
        this.$emit('nextStage');
      } catch ({errorMessage}) {
        this.errMessage = errorMessage;
      } finally {
        this.isCreating = false;
      }
    },
    prepUserData() {
      let userData;
      if (USER_TYPE.COMPANY === this.form.userType) {
        // eslint-disable-next-line no-unused-vars
        const {agreements, ...data} = this.form;
        userData = data;
      } else {
        // eslint-disable-next-line no-unused-vars
        const {agreements, organizationNumber, organizationName, ...data} = this.form;
        userData = data;
      }
      userData.phone = userData.phone.replace(/[^0-9+]/g, '');
      userData.emailLink = `${window.location.protocol}//${window.location.hostname}/signup/confirm/email`;
      return userData;
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-registration-base {
  &__choose {
    margin-bottom: 15px !important;
  }
  &__button {
    color: $--color-auth-primary;
  }
  &__checkbox {
    :deep .el-checkbox__input {
      @include mix--media('xs') {
        vertical-align: top;
      }
    }
  }
  &__checkbox-text {
    display: inline-block;
    @include mix--media('xs') {
      display: block;
    }
  }
  :deep .ex-authorization-form__form {
    padding-bottom: 0;
  }
  :deep .ex-authorization__sub-content {
    @include mix--media('xs') {
      display: none;
    }
  }
  &__radio-mini {
    display: none;
    @include mix--media('xs') {
      display: block;
      padding-bottom: 32px;
    }
  }
}
</style>
