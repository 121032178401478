// api
import ContentApi from '@/api/content';

// util
import {hasPayloadError} from '@/utils/error';
import {resetState} from '@/utils/storeUtils';
import {errorMsg} from '@/utils/notification';

// const
import {FETCH_BASIC_CURRENCIES} from '@/constants/events/content/actions.type';
import {getWSA} from '@/constants/events/actions.type';
const errorMessageBasicCurrencies = 'Basic currency set not received';

const initState = {
  meta: {
    loading: {
      isFetchingGDPR: false,
      isFetchingContent: false,
      isBasicCurrencies: false,
    },
  },
  GDPR: {
    version: '',
    text: '',
  },
  content: {
    brand: null,
    supportEmail: null,
    onboardingEmail: null,
    availableSections: {
      gateway: false,
      autoTrading: false,
    },
  },
  currencies: [],
};

const state = {...initState};

const mutations = {
  reset(state) {
    resetState(initState, state);
  },
  setLoadingStatus(state, objectId) {
    state.meta.loading[objectId] = true;
  },
  removeLoadingStatus(state, objectId) {
    state.meta.loading[objectId] = false;
  },
  setGDPR(state, gdpr) {
    state.GDPR = {...gdpr};
  },
  setContent(state, content) {
    Object.assign(state.content, content);
  },
  setBasicCurrencies(state, currencies) {
    Object.assign(state.currencies, currencies);
  },
};

const actions = {

  // TODO Take into account when initializing the application

  async fetchGDPR({commit}) {
    // todo Visualize the loading process
    const errorMessage = 'Not received the current version GDPR policy';
    try {
      const result = await ContentApi.fetch();
      commit('setLoadingStatus', 'isFetchingGDPR');
      if (hasPayloadError(result?.response?.data) || hasPayloadError(result.data)) {
        commit('removeLoadingStatus', 'isFetchingGDPR');
        return errorMsg(errorMessage);
      }
      commit('setGDPR', result.data);
    } catch (e) {
      return errorMsg(errorMessage);
    } finally {
      commit('removeLoadingStatus', 'isFetchingGDPR');
    }
    // todo Visualize the loading process
  },
  async fetchBasicContent({commit}) {
    const errorMessage = 'Not received the content';
    try {
      commit('setLoadingStatus', 'isFetchingContent');
      const content = await ContentApi.fetchBasicContent();
      if (hasPayloadError(content?.response?.data) || hasPayloadError(content.data)) {
        return errorMsg(errorMessage);
      }
      commit('setContent', content.data);
    } catch (e) {
      return errorMsg(errorMessage);
    } finally {
      commit('removeLoadingStatus', 'isFetchingContent');
    }
  },
  async [FETCH_BASIC_CURRENCIES]({commit}) {
    try {
      await ContentApi.fetchBasicCurrencies(FETCH_BASIC_CURRENCIES);
    } catch (e) {
      return errorMsg(errorMessageBasicCurrencies);
    } finally {
      commit('removeLoadingStatus', 'isBasicCurrencies');
    }
  },
  [getWSA(FETCH_BASIC_CURRENCIES)]({commit}, payload) {
    commit('removeLoadingStatus', 'isBasicCurrencies');
    if (hasPayloadError(payload)) return errorMsg(errorMessageBasicCurrencies);
    commit('setBasicCurrencies', payload?.list);
  },
};

export default {namespaced: true, state, mutations, actions};
