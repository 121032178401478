import {hasPayloadError} from '@/utils/error';
import ErrorNotifier from '@/utils/socketError/errorNotifier';
import StoreErrorNotifier from '@/store/storeErrorNotifier';
import {errorMsg} from '@/utils/notification';
import store from '@/store';

/**
 **/
export default class SocketErrorHandler {
  /**
   * @param {Object} vueSocketIO
   */
  constructor(vueSocketIO) {
    this.subscribeToSocketMessages(vueSocketIO);
  }

  /**
   * @param {Object} vueSocketIO
   */
  subscribeToSocketMessages(vueSocketIO) {
    const socket = vueSocketIO.io;
    socket.onevent = (packet) => {
      let [event, ...args] = packet.data;
      if (args.length === 1) args = args[0];
      if (hasPayloadError(args)) this.handleError(event, args);
      vueSocketIO.listener.onEvent(event, args);
    };
  }

  /**
   * @param {string} event
   * @param {Object} args
   */
  handleError(event, args) {
    const code = args.error?.code;
    if (code && ErrorNotifier.isCriticalError(code)) {
      if (ErrorNotifier.parseDirtyCode(code)?.code === 550) errorMsg(ErrorNotifier.getMessage({error: {code}}, event));
      store.dispatch('auth/logout');
    }
    StoreErrorNotifier.handleError(event, args);
  }
}
